import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import {
  Calculate,
  CameraAlt,
  Home,
  AccessTime,
  AccountBalance,
  Business,
  Receipt,
  EventNote,
  Timer,
  MonetizationOn,
  ShowChart,
  AttachMoney,
} from '@mui/icons-material';

export default function CalculatorLanding() {
  const calculators = [
    {
      title: 'PAYE Calculator',
      description: 'Calculate your Pay As You Earn tax based on the latest KRA rates',
      path: '/calculators/paye',
      icon: Calculate,
    },
    {
      title: 'Inflation Calculator',
      description: 'Calculate the impact of inflation on purchasing power over time',
      path: '/calculators/inflation',
      icon: ShowChart,
    },
    {
      title: 'Session Pricing Calculator',
      description: 'Set professional photography session prices based on various factors',
      path: '/calculators/session-pricing',
      icon: CameraAlt,
    },
    {
      title: 'Real Estate Commission',
      description: 'Calculate real estate agent commissions from property sales',
      path: '/calculators/real-estate-commission',
      icon: Home,
    },
    {
      title: 'Hourly Rate Calculator',
      description: 'Determine competitive consulting rates based on experience and market',
      path: '/calculators/hourly-rate',
      icon: AccessTime,
    },
    {
      title: 'Self-Employed Tax',
      description: 'Estimate tax liabilities for self-employed professionals',
      path: '/calculators/self-employed-tax',
      icon: AccountBalance,
    },
    {
      title: 'Project Cost Estimator',
      description: 'Calculate total project costs including materials and labor',
      path: '/calculators/project-cost',
      icon: Business,
    },
    {
      title: 'Loan Calculator',
      description: 'Calculate loan payments and view amortization schedules',
      path: '/calculators/loan',
      icon: MonetizationOn,
    },
    {
      title: 'Time Sheet Calculator',
      description: 'Track and calculate billable hours for professionals',
      path: '/calculators/timesheet',
      icon: Timer,
    },
    {
      title: 'Contract Expiration',
      description: 'Manage contract renewals and calculate end dates',
      path: '/calculators/contract-expiration',
      icon: EventNote,
    },
    {
      title: 'Late Fee Calculator',
      description: 'Calculate late payment fees for overdue invoices',
      path: '/calculators/late-fee',
      icon: Receipt,
    },
    {
      title: 'Breakeven Analysis',
      description: 'Determine when revenues will cover costs for business planning',
      path: '/calculators/breakeven',
      icon: AttachMoney,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="flex items-center justify-center gap-3 mb-8">
            <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600">
              <Calculate className="h-6 w-6 text-white" />
            </div>
          </div>
          <h1 className="text-4xl font-bold text-slate-800 mb-4">
            Financial Calculators
          </h1>
          <p className="text-lg text-slate-600 max-w-2xl mx-auto">
            Professional calculators to help you make informed financial decisions
            and streamline your business operations.
          </p>
        </motion.div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {calculators.map((calculator, index) => (
            <motion.div
              key={calculator.path}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Link
                to={calculator.path}
                className="block group relative rounded-xl border border-slate-200 bg-white p-6 hover:shadow-lg transition-all"
              >
                <div className="flex items-center gap-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-red-600/10 group-hover:bg-red-600">
                    <calculator.icon className="h-6 w-6 text-red-600 group-hover:text-white" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-slate-800">{calculator.title}</h3>
                    <p className="mt-2 text-sm text-slate-600">
                      {calculator.description}
                    </p>
                  </div>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mt-16 bg-slate-50 rounded-xl p-8"
        >
          <h2 className="text-2xl font-bold text-slate-800 mb-4">
            Why Use Our Calculators?
          </h2>
          <div className="grid gap-6 md:grid-cols-3">
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Professional</h3>
              <p className="text-slate-600">
                Industry-standard calculations for business professionals
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Easy to Use</h3>
              <p className="text-slate-600">
                Simple interface with clear instructions and instant results
              </p>
            </div>
            <div>
              <h3 className="font-semibold text-slate-800 mb-2">Comprehensive</h3>
              <p className="text-slate-600">
                Wide range of calculators for various business needs
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}