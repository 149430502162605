import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
  Box,
  Paper
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';

interface ChartData {
  units: number;
  revenue: number;
  totalCosts: number;
  profit: number;
}

interface CalculatorMetaProps {
  title: string;
  description: string;
  calculatorType: string;
  features: string[];
  keywords: string[];
}

const CalculatorMeta: React.FC<CalculatorMetaProps> = ({ 
  title, 
  description, 
  calculatorType, 
  features, 
  keywords 
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
    </>
  );
};

export default function BreakevenCalculator() {
  const [fixedCosts, setFixedCosts] = useState('');
  const [variableCost, setVariableCost] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [result, setResult] = useState({
    breakevenUnits: 0,
    breakevenRevenue: 0,
    chartData: [] as ChartData[],
  });

  const calculateBreakeven = () => {
    const fixed = parseFloat(fixedCosts.replace(/,/g, ''));
    const variable = parseFloat(variableCost.replace(/,/g, ''));
    const price = parseFloat(sellingPrice.replace(/,/g, ''));

    if (isNaN(fixed) || isNaN(variable) || isNaN(price) || price <= variable) return;

    // Calculate breakeven point
    const breakevenUnits = Math.ceil(fixed / (price - variable));
    const breakevenRevenue = breakevenUnits * price;

    // Generate chart data
    const maxUnits = breakevenUnits * 2;
    const chartData: ChartData[] = [];
    
    for (let units = 0; units <= maxUnits; units += Math.ceil(maxUnits / 10)) {
      const revenue = units * price;
      const totalCosts = fixed + (units * variable);
      chartData.push({
        units,
        revenue,
        totalCosts,
        profit: revenue - totalCosts,
      });
    }

    setResult({
      breakevenUnits,
      breakevenRevenue,
      chartData,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Fixed Costs"
          value={fixedCosts}
          onChange={(e) => setFixedCosts(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Variable Cost per Unit"
          value={variableCost}
          onChange={(e) => setVariableCost(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Selling Price per Unit"
          value={sellingPrice}
          onChange={(e) => setSellingPrice(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );

  const resultSection = result.breakevenUnits > 0 && (
    <Box>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ 
            p: 2, 
            textAlign: 'center',
            background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
          }}>
            <Typography variant="subtitle2" color="textSecondary">
              Break-Even Units
            </Typography>
            <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
              {result.breakevenUnits.toLocaleString()}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ 
            p: 2, 
            textAlign: 'center',
            background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
          }}>
            <Typography variant="subtitle2" color="textSecondary">
              Break-Even Revenue
            </Typography>
            <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
              {formatCurrency(result.breakevenRevenue)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ height: 400, mt: 4 }}>
        <ResponsiveContainer>
          <LineChart
            data={result.chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="units"
              label={{
                value: 'Units Sold',
                position: 'insideBottom',
                offset: -5,
              }}
            />
            <YAxis
              label={{
                value: 'Amount ($)',
                angle: -90,
                position: 'insideLeft',
              }}
            />
            <Tooltip
              formatter={(value: number) => formatCurrency(value)}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="revenue"
              stroke="#2196f3"
              name="Revenue"
            />
            <Line
              type="monotone"
              dataKey="totalCosts"
              stroke="#f44336"
              name="Total Costs"
            />
            <Line
              type="monotone"
              dataKey="profit"
              stroke="#4caf50"
              name="Profit/Loss"
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        A breakeven analysis helps you determine when your business will become profitable.
        Enter your costs and pricing information to find out:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Fixed Costs:</strong> Expenses that don't change with production
            (rent, salaries, etc.)
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Variable Costs:</strong> Expenses that change with each unit
            (materials, direct labor, etc.)
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Selling Price:</strong> Revenue earned per unit sold
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Break-Even Analysis Calculator"
        description="Free break-even analysis calculator to find your business's break-even point. Calculate how many units you need to sell to cover costs and start making profit."
        calculatorType="breakeven"
        features={[
          'Calculate break-even point in units and revenue',
          'Interactive profit/loss visualization',
          'Dynamic cost analysis',
          'Real-time calculations',
          'Export results as PDF'
        ]}
        keywords={[
          'break even calculator',
          'break even analysis',
          'profit calculator',
          'business calculator',
          'cost analysis tool',
          'financial planning',
          'business profitability',
          'fixed costs',
          'variable costs',
          'revenue analysis'
        ]}
      />
      <Box sx={{ 
        maxWidth: '1200px', 
        margin: '0 auto',
        padding: '2rem',
        '& .MuiPaper-root': {
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
        }
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 700,
            color: '#1a237e',
            textAlign: 'center',
            mb: 4
          }}
        >
          Break-Even Analysis Calculator
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 3, height: '100%' }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Enter Your Costs
              </Typography>
              {inputSection}
              <Button
                variant="contained"
                onClick={calculateBreakeven}
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  background: 'linear-gradient(45deg, #1a237e 30%, #3949ab 90%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #0d1642 30%, #2c3a8c 90%)',
                  }
                }}
              >
                Calculate Break-Even Point
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Results
              </Typography>
              {resultSection}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
