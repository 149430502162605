import React from 'react';
import { Link } from 'react-router-dom';
import { industries } from '../../data/industries';

interface InternalLinkingProps {
  currentIndustry: string;
  currentDocType: string;
}

const validDocTypes = [
  'contract',
  'invoice',
  'proposal',
  'receipt',
  'quote',
  'sow',
  'brief',
  'nda',
  'timesheet',
  'expense',
  'status'
];

export default function InternalLinking({ currentIndustry, currentDocType }: InternalLinkingProps) {
  // Find related industries
  const currentIndustryData = industries.find(ind => ind.slug === currentIndustry);
  const relatedIndustries = industries
    .filter(ind => ind.slug !== currentIndustry)
    .slice(0, 3);

  // Find related document types
  const relatedDocTypes = validDocTypes
    .filter(docType => docType !== currentDocType)
    .slice(0, 3);

  return (
    <div className="mt-16 space-y-12">
      {/* Related Industries Section */}
      <div>
        <h2 className="text-2xl font-bold text-slate-800 mb-6">
          Related Industries
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {relatedIndustries.map(industry => (
            <Link
              key={industry.slug}
              to={`/${currentDocType}/${industry.slug}`}
              className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <h3 className="text-lg font-semibold text-slate-800 mb-2">
                {industry.name}
              </h3>
              <p className="text-sm text-slate-600">
                {industry.description || `Professional ${industry.name.toLowerCase()} services with customized document templates.`}
              </p>
            </Link>
          ))}
        </div>
      </div>

      {/* Related Document Types Section */}
      <div>
        <h2 className="text-2xl font-bold text-slate-800 mb-6">
          Related Documents
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {relatedDocTypes.map(docType => (
            <Link
              key={docType}
              to={`/${docType}/${currentIndustry}`}
              className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <h3 className="text-lg font-semibold text-slate-800 mb-2">
                {docType.charAt(0).toUpperCase() + docType.slice(1)}
              </h3>
              <p className="text-sm text-slate-600">
                Create professional {docType}s specifically designed for {currentIndustryData?.name.toLowerCase() || 'your industry'}.
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
} 