import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { addDays, addMonths, addYears, format, differenceInDays } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

interface CalculatorMetaProps {
  title: string;
  description: string;
  calculatorType: string;
  features: string[];
  keywords: string[];
}

const CalculatorMeta: React.FC<CalculatorMetaProps> = ({ 
  title, 
  description, 
  calculatorType, 
  features, 
  keywords 
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
    </>
  );
};

const ContractExpirationCalculator = () => {
  const [startDate, setStartDate] = useState('');
  const [duration, setDuration] = useState('');
  const [durationType, setDurationType] = useState('days');
  const [result, setResult] = useState({
    endDate: null as Date | null,
    daysRemaining: 0,
    notificationDate: null as Date | null,
  });

  const calculateEndDate = () => {
    if (!startDate || !duration) return;

    const start = new Date(startDate);
    const durationNum = parseInt(duration);

    let end: Date;
    switch (durationType) {
      case 'days':
        end = addDays(start, durationNum);
        break;
      case 'months':
        end = addMonths(start, durationNum);
        break;
      case 'years':
        end = addYears(start, durationNum);
        break;
      default:
        return;
    }

    // Calculate notification date (30 days before expiration)
    const notification = addDays(end, -30);
    
    // Calculate days remaining
    const today = new Date();
    const remaining = differenceInDays(end, today);

    setResult({
      endDate: end,
      daysRemaining: remaining,
      notificationDate: notification,
    });
  };

  const formatDate = (date: Date) => {
    return format(date, 'MMMM d, yyyy');
  };

  return (
    <>
      <CalculatorMeta
        title="Contract Expiration Date Calculator"
        description="Free contract expiration calculator to determine end dates and notification periods. Perfect for managing contract deadlines and renewal reminders."
        calculatorType="contract-expiration"
        features={[
          'Calculate contract end dates',
          'Automatic notification date calculation',
          'Days remaining counter',
          'Support for days, months, and years',
          'Renewal reminder alerts'
        ]}
        keywords={[
          'contract expiration calculator',
          'contract end date calculator',
          'agreement duration calculator',
          'contract renewal calculator',
          'contract term calculator',
          'contract management tool',
          'contract notification calculator',
          'legal deadline calculator',
          'business contract tool',
          'contract period calculator'
        ]}
      />
      <Box sx={{ 
        maxWidth: '1200px', 
        margin: '0 auto',
        padding: '2rem',
        '& .MuiPaper-root': {
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
        }
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 700,
            color: '#1a237e',
            textAlign: 'center',
            mb: 4
          }}
        >
          Contract Expiration Calculator
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Contract Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Contract Start Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Contract Duration"
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    inputProps={{ min: 1 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    select
                    label="Duration Type"
                    value={durationType}
                    onChange={(e) => setDurationType(e.target.value)}
                  >
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                    <option value="years">Years</option>
                  </TextField>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                onClick={calculateEndDate}
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  background: 'linear-gradient(45deg, #1a237e 30%, #3949ab 90%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #0d1642 30%, #2c3a8c 90%)',
                  }
                }}
              >
                Calculate Expiration Date
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Results
              </Typography>
              {result.endDate && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Contract End Date
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatDate(result.endDate)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Days Remaining
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {result.daysRemaining}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: result.daysRemaining <= 30 ? 'linear-gradient(45deg, #ffebee 30%, #ffcdd2 90%)' : 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Notification Date (30 days before expiration)
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatDate(result.notificationDate!)}
                      </Typography>
                      {result.daysRemaining <= 30 && (
                        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                          ⚠️ Contract is approaching expiration
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContractExpirationCalculator;
