import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { industries, Industry, SubCategory } from '../data/industries';
import IndustryTemplate from '../components/DynamicSEO/IndustryTemplate';
import DynamicMeta from '../components/DynamicSEO/DynamicMeta';
import InternalLinking from '../components/DynamicSEO/InternalLinking';
import Breadcrumb from '../components/DynamicSEO/Breadcrumb';
import Header from '../components/Header';
import Footer from '../components/Footer';

interface DynamicIndustryPageParams {
  documentType: string;
  industry: string;
}

const validDocTypes = [
  'contract',
  'invoice',
  'proposal',
  'receipt',
  'quote',
  'sow',
  'brief',
  'nda',
  'timesheet',
  'expense',
  'status'
];

const BASE_URL = 'https://lipana.app';

export default function DynamicIndustryPage() {
  const { documentType: rawDocType, industry: rawIndustry } = useParams<keyof DynamicIndustryPageParams>();
  const documentType = rawDocType?.toLowerCase();
  const industry = rawIndustry?.toLowerCase();

  // Validate document type and industry
  if (!documentType || !industry) {
    return <Navigate to="/" replace />;
  }

  // Find the industry data - check both main industries and subcategories
  const mainIndustry = industries.find(ind => ind.slug === industry) as Industry | undefined;
  const parentIndustry = industries.find(ind => 
    ind.subcategories?.some((sub: SubCategory) => sub.slug === industry)
  ) as Industry | undefined;
  
  const subcategory = parentIndustry?.subcategories?.find((sub: SubCategory) => sub.slug === industry);

  // If we found a subcategory but no parent industry, something is wrong
  if (subcategory && !parentIndustry) {
    console.error('Found subcategory but no parent industry:', { industry, subcategory });
    return <Navigate to="/" replace />;
  }

  // Create industryData by combining subcategory with parent industry's benefits
  const industryData = subcategory && parentIndustry ? {
    ...subcategory,
    benefits: parentIndustry.benefits,
    description: subcategory.description || `Professional ${subcategory.name.toLowerCase()} services with customized document templates.`
  } : mainIndustry;

  if (!industryData) {
    return <Navigate to="/" replace />;
  }

  // Validate document type
  if (!validDocTypes.includes(documentType)) {
    return <Navigate to="/" replace />;
  }

  const capitalizedIndustry = industryData.name;
  const capitalizedDocType = documentType
    .split('-')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const pageTitle = `${capitalizedDocType} Generator for ${capitalizedIndustry} | Free Professional Templates`;
  const pageDescription = `Create professional ${documentType}s specifically designed for ${industryData.name}. Free online ${documentType} generator with industry-specific templates and automatic calculations.`;
  const canonicalUrl = `${BASE_URL}/${documentType}/${industry}`;

  return (
    <div className="min-h-screen">
      <DynamicMeta
        title={pageTitle}
        description={pageDescription}
        canonicalUrl={canonicalUrl}
        industry={capitalizedIndustry}
        documentType={capitalizedDocType}
        imageUrl={`${BASE_URL}/og-image/${documentType}-${industry}.png`}
      />
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Breadcrumb documentType={documentType} industry={industry} />
        <IndustryTemplate
          documentType={documentType}
          industry={industryData}
          parentIndustry={parentIndustry}
        />
        <InternalLinking currentIndustry={industry} currentDocType={documentType} />
      </main>
      <Footer />
    </div>
  );
}