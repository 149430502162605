import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import CalculatorMeta from '../../components/DynamicSEO/CalculatorMeta';

const sessionTypes = [
  'Portrait',
  'Wedding',
  'Event',
  'Family',
  'Corporate',
  'Product',
];

const experienceLevels = [
  { value: 1, label: 'Beginner' },
  { value: 2, label: 'Intermediate' },
  { value: 3, label: 'Professional' },
  { value: 4, label: 'Expert' },
];

interface ChartData {
  name: string;
  amount: number;
}

interface CalculationResult {
  basePrice: number;
  equipmentCost: number;
  locationFee: number;
  experienceFee: number;
  totalPrice: number;
  pricePerHour: number;
  chartData: ChartData[];
}

export default function SessionPricingCalculator() {
  const [sessionType, setSessionType] = useState('');
  const [duration, setDuration] = useState(2);
  const [experience, setExperience] = useState(2);
  const [location, setLocation] = useState('studio');
  const [equipment, setEquipment] = useState(1000);
  const [result, setResult] = useState<CalculationResult>({
    basePrice: 0,
    equipmentCost: 0,
    locationFee: 0,
    experienceFee: 0,
    totalPrice: 0,
    pricePerHour: 0,
    chartData: [],
  });

  const calculatePrice = () => {
    // Base price calculation
    let basePrice = 0;
    switch (sessionType) {
      case 'Wedding':
        basePrice = 300;
        break;
      case 'Event':
        basePrice = 200;
        break;
      case 'Portrait':
        basePrice = 150;
        break;
      case 'Family':
        basePrice = 180;
        break;
      case 'Corporate':
        basePrice = 250;
        break;
      case 'Product':
        basePrice = 200;
        break;
      default:
        basePrice = 150;
    }

    // Adjustments based on factors
    const equipmentCost = (equipment / 1000) * 50; // $50 per $1000 of equipment
    const locationFee = location === 'onsite' ? 100 : 0;
    const experienceFee = basePrice * ((experience - 1) * 0.25); // 25% increase per level
    const durationMultiplier = duration / 2; // Base price is for 2 hours

    const totalPrice = (basePrice + equipmentCost + locationFee + experienceFee) * durationMultiplier;
    const pricePerHour = totalPrice / duration;

    // Prepare chart data
    const chartData: ChartData[] = [
      {
        name: 'Base Rate',
        amount: basePrice * durationMultiplier,
      },
      {
        name: 'Equipment',
        amount: equipmentCost * durationMultiplier,
      },
      {
        name: 'Location Fee',
        amount: locationFee * durationMultiplier,
      },
      {
        name: 'Experience',
        amount: experienceFee * durationMultiplier,
      },
    ];

    setResult({
      basePrice: basePrice * durationMultiplier,
      equipmentCost: equipmentCost * durationMultiplier,
      locationFee: locationFee * durationMultiplier,
      experienceFee: experienceFee * durationMultiplier,
      totalPrice,
      pricePerHour,
      chartData,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Session Type</InputLabel>
          <Select
            value={sessionType}
            label="Session Type"
            onChange={(e) => setSessionType(e.target.value)}
          >
            {sessionTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Session Duration (hours)</Typography>
        <Slider
          value={duration}
          onChange={(_, value) => setDuration(value as number)}
          step={0.5}
          marks
          min={1}
          max={8}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Experience Level</Typography>
        <Slider
          value={experience}
          onChange={(_, value) => setExperience(value as number)}
          step={1}
          marks={experienceLevels}
          min={1}
          max={4}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) =>
            experienceLevels.find((level) => level.value === value)?.label || ''
          }
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Location</InputLabel>
          <Select
            value={location}
            label="Location"
            onChange={(e) => setLocation(e.target.value)}
          >
            <MenuItem value="studio">Studio</MenuItem>
            <MenuItem value="onsite">On-site</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Equipment Value"
          value={equipment}
          onChange={(e) => setEquipment(Number(e.target.value))}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          type="number"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={calculatePrice}
          size="large"
        >
          Calculate Session Price
        </Button>
      </Grid>
    </Grid>
  );

  const resultSection = result.totalPrice > 0 && (
    <>
      <Typography variant="h5" gutterBottom>
        Session Pricing Breakdown
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="primary" gutterBottom>
            {formatCurrency(result.totalPrice)}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            ({formatCurrency(result.pricePerHour)} per hour)
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" gutterBottom>
              Price Breakdown
            </Typography>
            <Box sx={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <BarChart data={result.chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                  <Legend />
                  <Bar
                    dataKey="amount"
                    fill="#2196f3"
                    name="Amount"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {result.chartData.map((item) => (
              <Grid item xs={6} sm={3} key={item.name}>
                <Chip
                  label={`${item.name}: ${formatCurrency(item.amount)}`}
                  color="primary"
                  variant="outlined"
                  sx={{ width: '100%' }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        This calculator helps photographers determine competitive session pricing based on various factors:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Session Type:</strong> Different types of photography require different pricing strategies
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Duration:</strong> Longer sessions require more time and energy
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Experience:</strong> More experienced photographers can command higher rates
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Location:</strong> On-site sessions include travel and setup time
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Equipment:</strong> Higher-end equipment costs are factored into pricing
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Photography Session Pricing Calculator"
        description="Calculate professional photography session prices based on session type, duration, experience level, location, and equipment. Perfect for photographers to set competitive rates."
        calculatorType="session-pricing"
        features={[
          'Session type-based pricing',
          'Duration adjustment',
          'Experience level consideration',
          'Location fee calculation',
          'Equipment value factoring',
          'Visual price breakdown',
        ]}
        keywords={[
          'photography pricing',
          'session rates',
          'photographer calculator',
          'photo shoot pricing',
          'photography business',
          'professional photography',
          'pricing strategy',
          'photography rates',
        ]}
      />
      <CalculatorLayout
        title="Photography Session Pricing Calculator"
        description="Calculate professional photography session prices based on multiple factors"
        icon={PhotoCamera}
        inputSection={inputSection}
        resultSection={resultSection}
        helpSection={helpSection}
      />
    </>
  );
}
