import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Paper,
} from '@mui/material';
import { WorkOutline } from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import CalculatorMeta from '../../components/DynamicSEO/CalculatorMeta';

interface ChartData {
  name: string;
  amount: number;
  rate?: number;
}

interface CalculationResult {
  annualSalary: number;
  monthlyExpenses: number;
  profitMargin: number;
  billableHours: number;
  minimumRate: number;
  recommendedRate: number;
  competitiveRate: number;
  chartData: ChartData[];
  expenseBreakdown: ChartData[];
}

const experienceLevels = [
  { value: 'junior', label: 'Junior (1-3 years)', multiplier: 1 },
  { value: 'mid', label: 'Mid-Level (4-6 years)', multiplier: 1.5 },
  { value: 'senior', label: 'Senior (7-10 years)', multiplier: 2 },
  { value: 'expert', label: 'Expert (10+ years)', multiplier: 2.5 },
];

const industries = [
  { value: 'technology', label: 'Technology', multiplier: 1.2 },
  { value: 'finance', label: 'Finance', multiplier: 1.3 },
  { value: 'healthcare', label: 'Healthcare', multiplier: 1.1 },
  { value: 'education', label: 'Education', multiplier: 0.9 },
  { value: 'consulting', label: 'Consulting', multiplier: 1.4 },
];

export default function HourlyRateCalculator() {
  const [desiredAnnualIncome, setDesiredAnnualIncome] = useState('');
  const [monthlyExpenses, setMonthlyExpenses] = useState('');
  const [workHoursPerWeek, setWorkHoursPerWeek] = useState(40);
  const [vacationWeeks, setVacationWeeks] = useState(4);
  const [profitMargin, setProfitMargin] = useState(20);
  const [experienceLevel, setExperienceLevel] = useState('mid');
  const [industry, setIndustry] = useState('technology');
  const [result, setResult] = useState<CalculationResult>({
    annualSalary: 0,
    monthlyExpenses: 0,
    profitMargin: 0,
    billableHours: 0,
    minimumRate: 0,
    recommendedRate: 0,
    competitiveRate: 0,
    chartData: [],
    expenseBreakdown: [],
  });

  const calculateRate = () => {
    const annualSalary = parseFloat(desiredAnnualIncome.replace(/,/g, '')) || 0;
    const expenses = parseFloat(monthlyExpenses.replace(/,/g, '')) * 12 || 0;
    const workWeeks = 52 - vacationWeeks;
    const billableHours = workHoursPerWeek * workWeeks * 0.75; // Assuming 75% billable time
    const profitAmount = (annualSalary + expenses) * (profitMargin / 100);

    const baseRate = (annualSalary + expenses + profitAmount) / billableHours;
    const experienceMultiplier = experienceLevels.find(e => e.value === experienceLevel)?.multiplier || 1;
    const industryMultiplier = industries.find(i => i.value === industry)?.multiplier || 1;

    const minimumRate = baseRate;
    const recommendedRate = baseRate * experienceMultiplier;
    const competitiveRate = recommendedRate * industryMultiplier;

    const chartData = [
      { name: 'Minimum', rate: minimumRate, amount: minimumRate * billableHours },
      { name: 'Recommended', rate: recommendedRate, amount: recommendedRate * billableHours },
      { name: 'Competitive', rate: competitiveRate, amount: competitiveRate * billableHours },
    ];

    const expenseBreakdown = [
      { name: 'Salary', amount: annualSalary },
      { name: 'Expenses', amount: expenses },
      { name: 'Profit', amount: profitAmount },
    ];

    setResult({
      annualSalary,
      monthlyExpenses: expenses / 12,
      profitMargin,
      billableHours,
      minimumRate,
      recommendedRate,
      competitiveRate,
      chartData,
      expenseBreakdown,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatRate = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value) + '/hr';
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Desired Annual Income"
          value={desiredAnnualIncome}
          onChange={(e) => setDesiredAnnualIncome(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Monthly Business Expenses"
          value={monthlyExpenses}
          onChange={(e) => setMonthlyExpenses(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Experience Level</InputLabel>
          <Select
            value={experienceLevel}
            label="Experience Level"
            onChange={(e) => setExperienceLevel(e.target.value)}
          >
            {experienceLevels.map((level) => (
              <MenuItem key={level.value} value={level.value}>
                {level.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel>Industry</InputLabel>
          <Select
            value={industry}
            label="Industry"
            onChange={(e) => setIndustry(e.target.value)}
          >
            {industries.map((ind) => (
              <MenuItem key={ind.value} value={ind.value}>
                {ind.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Work Hours per Week</Typography>
        <Slider
          value={workHoursPerWeek}
          onChange={(_, value) => setWorkHoursPerWeek(value as number)}
          step={5}
          marks
          min={10}
          max={60}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Vacation Weeks per Year</Typography>
        <Slider
          value={vacationWeeks}
          onChange={(_, value) => setVacationWeeks(value as number)}
          step={1}
          marks
          min={0}
          max={8}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Profit Margin (%)</Typography>
        <Slider
          value={profitMargin}
          onChange={(_, value) => setProfitMargin(value as number)}
          step={5}
          marks
          min={0}
          max={50}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={calculateRate}
          size="large"
        >
          Calculate Hourly Rate
        </Button>
      </Grid>
    </Grid>
  );

  const resultSection = result.minimumRate > 0 && (
    <>
      <Typography variant="h5" gutterBottom>
        Recommended Hourly Rates
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Minimum Rate
                  </Typography>
                  <Typography variant="h4" color="error" gutterBottom>
                    {formatRate(result.minimumRate)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Covers basic expenses
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%', bgcolor: 'primary.light' }}>
                  <Typography variant="subtitle2" color="white">
                    Recommended Rate
                  </Typography>
                  <Typography variant="h4" color="white" gutterBottom>
                    {formatRate(result.recommendedRate)}
                  </Typography>
                  <Typography variant="body2" color="white">
                    Based on your experience
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Competitive Rate
                  </Typography>
                  <Typography variant="h4" color="success.main" gutterBottom>
                    {formatRate(result.competitiveRate)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Industry-adjusted rate
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Annual Income Projection
          </Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <ComposedChart data={result.chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />
                <Tooltip
                  formatter={(value, name) => [
                    formatCurrency(Number(value)),
                    name === 'rate' ? 'Hourly Rate' : 'Annual Income',
                  ]}
                />
                <Legend />
                <Bar
                  yAxisId="left"
                  dataKey="amount"
                  fill="#8884d8"
                  name="Annual Income"
                />
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="rate"
                  stroke="#82ca9d"
                  name="Hourly Rate"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Cost Breakdown
          </Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <BarChart data={result.expenseBreakdown}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                <Legend />
                <Bar dataKey="amount" fill="#82ca9d" name="Amount" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Calculation Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" color="text.secondary">
                  Billable Hours per Year
                </Typography>
                <Typography variant="body1">
                  {result.billableHours.toFixed(0)} hours
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" color="text.secondary">
                  Monthly Expenses
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(result.monthlyExpenses)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2" color="text.secondary">
                  Profit Margin
                </Typography>
                <Typography variant="body1">
                  {result.profitMargin}%
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        This calculator helps consultants and freelancers determine competitive hourly rates based on:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Desired Income:</strong> Your target annual income before taxes
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Business Expenses:</strong> Monthly costs of running your business
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Experience Level:</strong> Your expertise affects your market value
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Industry:</strong> Different sectors command different rates
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Work Schedule:</strong> Available billable hours impact your rate
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Profit Margin:</strong> Buffer for business growth and unexpected costs
          </Typography>
        </li>
      </ul>
      <Typography variant="body2" sx={{ mt: 2 }}>
        The calculator provides three rates:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Minimum Rate:</strong> Covers basic expenses and desired income
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Recommended Rate:</strong> Accounts for your experience level
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Competitive Rate:</strong> Adjusted for your industry standards
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Consultant Hourly Rate Calculator"
        description="Calculate competitive hourly rates based on your experience, industry, and business expenses. Perfect for consultants, freelancers, and independent professionals."
        calculatorType="hourly-rate"
        features={[
          'Experience-based rate calculation',
          'Industry-specific adjustments',
          'Business expense consideration',
          'Profit margin analysis',
          'Annual income projections',
          'Visual cost breakdown',
        ]}
        keywords={[
          'hourly rate calculator',
          'consultant rates',
          'freelance pricing',
          'professional fees',
          'service pricing',
          'consulting rates',
          'billable hours',
          'rate setting',
        ]}
      />
      <CalculatorLayout
        title="Consultant Hourly Rate Calculator"
        description="Calculate your optimal hourly rate based on experience, industry, and business expenses"
        icon={WorkOutline}
        inputSection={inputSection}
        resultSection={resultSection}
        helpSection={helpSection}
      />
    </>
  );
}
