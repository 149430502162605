import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown, Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import Logo from './Logo';
import XIcon from './icons/XIcon';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from '../lib/i18n/TranslationContext';

export default function Header() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCalculatorsOpen, setIsCalculatorsOpen] = useState(false);
  const [isDocsOpen, setIsDocsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  const tools = [
    { name: t('home.tools.contract'), path: '/contract' },
    { name: t('home.tools.invoice'), path: '/invoice' },
    { name: t('home.tools.proposal'), path: '/proposal' },
    { name: t('home.tools.receipt'), path: '/receipt' },
    { name: t('home.tools.quote'), path: '/quote' },
    { name: t('home.tools.sow'), path: '/sow' },
    { name: t('home.tools.brief'), path: '/brief' },
    { name: t('home.tools.nda'), path: '/nda' },
    { name: t('home.tools.timesheet'), path: '/timesheet' },
    { name: t('home.tools.expense'), path: '/expense' },
    { name: t('home.tools.status'), path: '/status' }
  ];

  const calculators = [
    { name: 'PAYE Calculator', path: '/calculators/paye' },
    { name: 'Inflation Calculator', path: '/calculators/inflation' },
    { name: 'Session Pricing Calculator', path: '/calculators/session-pricing' },
    { name: 'Real Estate Commission', path: '/calculators/real-estate-commission' },
    { name: 'Hourly Rate Calculator', path: '/calculators/hourly-rate' },
    { name: 'Self-Employed Tax', path: '/calculators/self-employed-tax' },
    { name: 'Project Cost Estimator', path: '/calculators/project-cost' },
    { name: 'Loan Calculator', path: '/calculators/loan' },
    { name: 'Time Sheet Calculator', path: '/calculators/timesheet' },
    { name: 'Contract Expiration', path: '/calculators/contract-expiration' },
    { name: 'Late Fee Calculator', path: '/calculators/late-fee' },
    { name: 'Breakeven Analysis', path: '/calculators/breakeven' },
    { name: 'Mortgage Calculator', path: '/calculators/mortgage' },
    { name: 'Gantt Chart', path: '/calculators/gantt' }
  ];

  const docs = [
    { name: 'M-PESA API', path: '/docs/mpesa-api' }
  ];

  return (
    <header className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <Logo />
            <span className="font-semibold text-slate-900">Lipana</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-6">
            {/* Tools Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Tools
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`} />
              </button>

              {isDropdownOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsDropdownOpen(false)}
                >
                  {tools.map((tool) => (
                    <Link
                      key={tool.path}
                      to={tool.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                      onClick={() => setIsDropdownOpen(false)}
                    >
                      {tool.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Calculators Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsCalculatorsOpen(!isCalculatorsOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Calculators
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isCalculatorsOpen ? 'rotate-180' : ''}`} />
              </button>

              {isCalculatorsOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsCalculatorsOpen(false)}
                >
                  {calculators.map((calculator) => (
                    <Link
                      key={calculator.path}
                      to={calculator.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                      onClick={() => setIsCalculatorsOpen(false)}
                    >
                      {calculator.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Documentation Dropdown */}
            <div className="relative">
              <button
                onClick={() => setIsDocsOpen(!isDocsOpen)}
                className="flex items-center gap-1 text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50"
              >
                Documentation
                <KeyboardArrowDown className={`w-5 h-5 transition-transform ${isDocsOpen ? 'rotate-180' : ''}`} />
              </button>

              {isDocsOpen && (
                <div 
                  className="absolute right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-slate-200 py-2"
                  onMouseLeave={() => setIsDocsOpen(false)}
                >
                  {docs.map((doc) => (
                    <Link
                      key={doc.path}
                      to={doc.path}
                      className="block px-4 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                      onClick={() => setIsDocsOpen(false)}
                    >
                      {doc.name}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Other desktop navigation items */}
            <Link to="/ai" className="text-sm text-slate-600 hover:text-slate-900 px-4 py-2 rounded-lg hover:bg-slate-50">
              AI
            </Link>
            <a
              href="https://twitter.com/LipanaApp"
              target="_blank"
              rel="noopener noreferrer"
              className="text-slate-400 hover:text-slate-500"
            >
              <XIcon className="w-5 h-5" />
            </a>
            <LanguageSwitcher />
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden p-2 rounded-lg text-slate-600 hover:text-slate-900 hover:bg-slate-50"
          >
            {isMobileMenuOpen ? <CloseIcon className="w-6 h-6" /> : <MenuIcon className="w-6 h-6" />}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white border-t border-slate-200">
          <div className="px-4 py-2 space-y-1">
            <div className="py-2">
              <div className="font-medium text-sm text-slate-900 px-3 py-2">Tools</div>
              {tools.map((tool) => (
                <Link
                  key={tool.path}
                  to={tool.path}
                  className="block px-3 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {tool.name}
                </Link>
              ))}
            </div>
            
            <div className="py-2 border-t border-slate-200">
              <div className="font-medium text-sm text-slate-900 px-3 py-2">Calculators</div>
              {calculators.map((calculator) => (
                <Link
                  key={calculator.path}
                  to={calculator.path}
                  className="block px-3 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {calculator.name}
                </Link>
              ))}
            </div>

            <div className="py-2 border-t border-slate-200">
              <div className="font-medium text-sm text-slate-900 px-3 py-2">Documentation</div>
              {docs.map((doc) => (
                <Link
                  key={doc.path}
                  to={doc.path}
                  className="block px-3 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {doc.name}
                </Link>
              ))}
            </div>

            <div className="py-2 border-t border-slate-200">
              <Link
                to="/ai"
                className="block px-3 py-2 text-sm text-slate-600 hover:bg-slate-50 hover:text-slate-900"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                AI
              </Link>
            </div>

            <div className="py-2 border-t border-slate-200 flex items-center justify-between px-3">
              <a
                href="https://twitter.com/LipanaApp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slate-400 hover:text-slate-500"
              >
                <XIcon className="w-5 h-5" />
              </a>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      )}
    </header>
  );
}
