import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { differenceInDays } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

interface CalculatorMetaProps {
  title: string;
  description: string;
  calculatorType: string;
  features: string[];
  keywords: string[];
}

const CalculatorMeta: React.FC<CalculatorMetaProps> = ({ 
  title, 
  description, 
  calculatorType, 
  features, 
  keywords 
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
    </>
  );
};

const LateFeeCalculator = () => {
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [feeType, setFeeType] = useState('percentage');
  const [feeRate, setFeeRate] = useState('');
  const [minimumFee, setMinimumFee] = useState('');
  const [maximumFee, setMaximumFee] = useState('');
  const [compoundDaily, setCompoundDaily] = useState(false);
  const [result, setResult] = useState({
    daysLate: 0,
    lateFee: 0,
    totalAmount: 0,
  });

  const calculateLateFee = () => {
    const amount = parseFloat(invoiceAmount.replace(/,/g, ''));
    const rate = parseFloat(feeRate);
    const minFee = parseFloat(minimumFee) || 0;
    const maxFee = parseFloat(maximumFee) || Infinity;

    if (isNaN(amount) || isNaN(rate) || !dueDate || !paymentDate) return;

    const due = new Date(dueDate);
    const payment = new Date(paymentDate);
    const daysLate = Math.max(0, differenceInDays(payment, due));

    let lateFee = 0;
    if (daysLate > 0) {
      if (feeType === 'percentage') {
        if (compoundDaily) {
          // Compound interest formula: A = P(1 + r)^t
          const dailyRate = rate / 100 / 365;
          lateFee = amount * (Math.pow(1 + dailyRate, daysLate) - 1);
        } else {
          // Simple interest
          lateFee = amount * (rate / 100) * (daysLate / 365);
        }
      } else {
        // Flat fee per day
        lateFee = rate * daysLate;
      }
    }

    // Apply minimum and maximum constraints
    lateFee = Math.max(minFee, Math.min(maxFee, lateFee));

    setResult({
      daysLate,
      lateFee,
      totalAmount: amount + lateFee,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  return (
    <>
      <CalculatorMeta
        title="Late Fee Calculator"
        description="Free late fee calculator to determine payment penalties and total amounts due. Perfect for landlords, lenders, and businesses to calculate late payment charges."
        calculatorType="late-fee"
        features={[
          'Calculate late payment penalties',
          'Support for percentage and flat-rate fees',
          'Compound interest calculation',
          'Minimum/maximum fee limits',
          'Days overdue calculation',
          'Total amount due summary'
        ]}
        keywords={[
          'late fee calculator',
          'payment penalty calculator',
          'late payment calculator',
          'overdue payment calculator',
          'late rent calculator',
          'penalty fee calculator',
          'compound interest calculator',
          'late charge calculator',
          'payment delay calculator',
          'finance charge calculator'
        ]}
      />
      <Box sx={{ 
        maxWidth: '1200px', 
        margin: '0 auto',
        padding: '2rem',
        '& .MuiPaper-root': {
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
        }
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 700,
            color: '#1a237e',
            textAlign: 'center',
            mb: 4
          }}
        >
          Late Fee Calculator
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Payment Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Invoice Amount"
                    value={invoiceAmount}
                    onChange={(e) => setInvoiceAmount(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Due Date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Payment Date"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Fee Type"
                    value={feeType}
                    onChange={(e) => setFeeType(e.target.value)}
                  >
                    <option value="percentage">Percentage of Invoice</option>
                    <option value="flat">Flat Rate per Day</option>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={feeType === 'percentage' ? 'Annual Percentage Rate' : 'Daily Fee'}
                    value={feeRate}
                    onChange={(e) => setFeeRate(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {feeType === 'percentage' ? '%' : '$'}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Minimum Fee"
                    value={minimumFee}
                    onChange={(e) => setMinimumFee(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Maximum Fee"
                    value={maximumFee}
                    onChange={(e) => setMaximumFee(e.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={compoundDaily}
                        onChange={(e) => setCompoundDaily(e.target.checked)}
                      />
                    }
                    label="Compound Interest Daily"
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                onClick={calculateLateFee}
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  background: 'linear-gradient(45deg, #1a237e 30%, #3949ab 90%)',
                  color: 'white',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #0d1642 30%, #2c3a8c 90%)',
                  }
                }}
              >
                Calculate Late Fee
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Results
              </Typography>
              {result.daysLate > 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Days Late
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {result.daysLate}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Late Fee
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatCurrency(result.lateFee)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #ffebee 30%, #ffcdd2 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Total Amount Due
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatCurrency(result.totalAmount)}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default LateFeeCalculator;
