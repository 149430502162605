import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

interface MaterialItem {
  name: string;
  quantity: number;
  unitPrice: number;
}

interface LaborItem {
  description: string;
  hours: number;
  rate: number;
}

const ProjectCostCalculator = () => {
  const [materials, setMaterials] = useState<MaterialItem[]>([
    { name: '', quantity: 0, unitPrice: 0 },
  ]);
  const [labor, setLabor] = useState<LaborItem[]>([
    { description: '', hours: 0, rate: 0 },
  ]);
  const [overhead, setOverhead] = useState(20);
  const [contingency, setContingency] = useState(10);
  const [profit, setProfit] = useState(15);
  const [result, setResult] = useState({
    materialsCost: 0,
    laborCost: 0,
    overheadCost: 0,
    contingencyCost: 0,
    profitAmount: 0,
    totalCost: 0,
  });

  const handleMaterialChange = (index: number, field: keyof MaterialItem, value: string) => {
    const newMaterials = [...materials];
    newMaterials[index] = {
      ...newMaterials[index],
      [field]: field === 'name' ? value : parseFloat(value) || 0,
    };
    setMaterials(newMaterials);
  };

  const handleLaborChange = (index: number, field: keyof LaborItem, value: string) => {
    const newLabor = [...labor];
    newLabor[index] = {
      ...newLabor[index],
      [field]: field === 'description' ? value : parseFloat(value) || 0,
    };
    setLabor(newLabor);
  };

  const addMaterial = () => {
    setMaterials([...materials, { name: '', quantity: 0, unitPrice: 0 }]);
  };

  const addLabor = () => {
    setLabor([...labor, { description: '', hours: 0, rate: 0 }]);
  };

  const removeMaterial = (index: number) => {
    setMaterials(materials.filter((_, i) => i !== index));
  };

  const removeLabor = (index: number) => {
    setLabor(labor.filter((_, i) => i !== index));
  };

  const calculateCost = () => {
    const materialsCost = materials.reduce(
      (sum, item) => sum + item.quantity * item.unitPrice,
      0
    );

    const laborCost = labor.reduce(
      (sum, item) => sum + item.hours * item.rate,
      0
    );

    const subtotal = materialsCost + laborCost;
    const overheadCost = subtotal * (overhead / 100);
    const contingencyCost = subtotal * (contingency / 100);
    const profitAmount = subtotal * (profit / 100);
    const totalCost = subtotal + overheadCost + contingencyCost + profitAmount;

    setResult({
      materialsCost,
      laborCost,
      overheadCost,
      contingencyCost,
      profitAmount,
      totalCost,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Project Cost Estimator
        </Typography>

        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Materials
          </Typography>
          {materials.map((material, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Material Name"
                  value={material.name}
                  onChange={(e) => handleMaterialChange(index, 'name', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Quantity"
                  value={material.quantity}
                  onChange={(e) => handleMaterialChange(index, 'quantity', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Unit Price"
                  value={material.unitPrice}
                  onChange={(e) => handleMaterialChange(index, 'unitPrice', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  color="error"
                  onClick={() => removeMaterial(index)}
                  disabled={materials.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addMaterial}
            variant="outlined"
            sx={{ mb: 3 }}
          >
            Add Material
          </Button>

          <Typography variant="h6" gutterBottom>
            Labor
          </Typography>
          {labor.map((item, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Description"
                  value={item.description}
                  onChange={(e) => handleLaborChange(index, 'description', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Hours"
                  value={item.hours}
                  onChange={(e) => handleLaborChange(index, 'hours', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  type="number"
                  label="Hourly Rate"
                  value={item.rate}
                  onChange={(e) => handleLaborChange(index, 'rate', e.target.value)}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  color="error"
                  onClick={() => removeLabor(index)}
                  disabled={labor.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={addLabor}
            variant="outlined"
            sx={{ mb: 3 }}
          >
            Add Labor
          </Button>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Overhead %"
                type="number"
                value={overhead}
                onChange={(e) => setOverhead(Number(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Contingency %"
                type="number"
                value={contingency}
                onChange={(e) => setContingency(Number(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Profit Margin %"
                type="number"
                value={profit}
                onChange={(e) => setProfit(Number(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={calculateCost}
            size="large"
            sx={{ mt: 3 }}
          >
            Calculate Total Cost
          </Button>
        </StyledPaper>

        {result.totalCost > 0 && (
          <StyledPaper>
            <Typography variant="h5" gutterBottom>
              Cost Breakdown
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Materials Cost:</Typography>
                <Typography variant="h6">{formatCurrency(result.materialsCost)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Labor Cost:</Typography>
                <Typography variant="h6">{formatCurrency(result.laborCost)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Overhead:</Typography>
                <Typography variant="h6">{formatCurrency(result.overheadCost)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Contingency:</Typography>
                <Typography variant="h6">{formatCurrency(result.contingencyCost)}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">Profit:</Typography>
                <Typography variant="h6">{formatCurrency(result.profitAmount)}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" color="primary" sx={{ mt: 2 }}>
                  Total Project Cost: {formatCurrency(result.totalCost)}
                </Typography>
              </Grid>
            </Grid>
          </StyledPaper>
        )}
      </Box>
    </Container>
  );
};

export default ProjectCostCalculator;
