import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';

interface Industry {
  slug: string;
  name: string;
  description: string;
  benefits?: Array<{
    title: string;
    description: string;
    icon: any;
    color: string;
  }>;
}

interface IndustryTemplateProps {
  industry: Industry;
  parentIndustry?: Industry;
  documentType: string;
}

export default function IndustryTemplate({ industry, parentIndustry, documentType }: IndustryTemplateProps) {
  const benefits = parentIndustry?.benefits || industry.benefits || [];
  const description = parentIndustry?.description || industry.description;

  const capitalizedIndustry = industry.name;
  const capitalizedDocType = documentType.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    'name': `Lipana ${capitalizedDocType} Generator for ${capitalizedIndustry}`,
    'applicationCategory': 'BusinessApplication',
    'operatingSystem': 'Web',
    'offers': {
      '@type': 'Offer',
      'price': '0',
      'priceCurrency': 'USD'
    },
    'description': `Create professional ${documentType.toLowerCase()}s tailored for ${capitalizedIndustry}. Free online ${documentType.toLowerCase()} generator with industry-specific templates and automatic calculations.`,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '4.8',
      'ratingCount': '1250'
    },
    'featureList': [
      `Industry-specific ${documentType.toLowerCase()} templates for ${capitalizedIndustry.toLowerCase()}`,
      'Professional document formatting',
      'Easy customization options',
      'Instant document generation',
      'Free to use'
    ],
    'review': {
      '@type': 'Review',
      'reviewRating': {
        '@type': 'Rating',
        'ratingValue': '5',
        'bestRating': '5'
      },
      'author': {
        '@type': 'Person',
        'name': 'Business Owner'
      },
      'reviewBody': `Excellent ${documentType.toLowerCase()} generator for ${capitalizedIndustry.toLowerCase()} businesses. Saves time and looks professional.`
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${capitalizedDocType} Generator for ${capitalizedIndustry} | Free Professional Templates`}</title>
        <meta 
          name="description" 
          content={`Generate professional ${documentType.toLowerCase()}s specifically designed for ${capitalizedIndustry}. Free online ${documentType.toLowerCase()} generator with industry-specific templates, automatic calculations, and customizable formats.`} 
        />
        <meta 
          name="keywords" 
          content={`${capitalizedIndustry.toLowerCase()} ${documentType.toLowerCase()}, ${capitalizedIndustry.toLowerCase()} template, professional ${documentType.toLowerCase()}, ${documentType.toLowerCase()} generator, free ${documentType.toLowerCase()}, online ${documentType.toLowerCase()}, ${capitalizedIndustry.toLowerCase()} business, business documents`} 
        />
        <link rel="canonical" href={`https://lipana.app/${documentType.toLowerCase()}/${industry.slug}`} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={`${capitalizedDocType} Generator for ${capitalizedIndustry} | Free Professional Templates`} />
        <meta property="og:description" content={`Generate professional ${documentType.toLowerCase()}s specifically designed for ${capitalizedIndustry}. Free online ${documentType.toLowerCase()} generator with industry-specific templates.`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://lipana.app/${documentType.toLowerCase()}/${industry.slug}`} />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${capitalizedDocType} Generator for ${capitalizedIndustry}`} />
        <meta name="twitter:description" content={`Generate professional ${documentType.toLowerCase()}s specifically designed for ${capitalizedIndustry}. Free online ${documentType.toLowerCase()} generator.`} />
        
        {/* Additional meta tags for better SEO */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Lipana" />
        <meta name="revisit-after" content="7 days" />
        
        {/* Additional social media tags */}
        <meta property="og:site_name" content="Lipana" />
        <meta property="og:image:alt" content={`${capitalizedDocType} Generator for ${capitalizedIndustry}`} />
        <meta name="twitter:site" content="@lipana" />
        
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main className="bg-gray-50">
        {/* Hero Section with Gradient Background */}
        <div className="bg-gradient-to-r from-indigo-600 via-purple-600 to-indigo-800 text-white relative overflow-hidden">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
          <div className="container mx-auto px-4 py-20">
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                <div className="flex-1">
                  <h1 className="text-4xl md:text-5xl font-bold mb-4">
                    {capitalizedDocType} Generator for {capitalizedIndustry}
                  </h1>
                  <p className="text-xl text-gray-100 mb-8">{description}</p>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <Link
                      to={`/${documentType}/generate`}
                      className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-semibold rounded-lg shadow-lg bg-white text-indigo-600 hover:bg-gray-50 transform hover:scale-105 transition-all duration-200"
                    >
                      Create {capitalizedDocType} Now
                      <ArrowForward className="ml-2 -mr-1 h-5 w-5" />
                    </Link>
                    <a
                      href="#features"
                      className="inline-flex items-center justify-center px-8 py-4 border-2 border-white text-lg font-semibold rounded-lg text-white hover:bg-white hover:text-indigo-600 transition-all duration-200"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className="flex-1 hidden md:block">
                  <div className="relative">
                    <div className="absolute inset-0 bg-white/10 backdrop-blur-sm rounded-lg transform rotate-6"></div>
                    <div className="relative bg-white/90 p-6 rounded-lg shadow-xl">
                      <div className="space-y-4">
                        <div className="h-8 bg-gray-200 rounded w-3/4"></div>
                        <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                        <div className="h-4 bg-gray-200 rounded w-4/6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Trust Indicators Section */}
        <div className="bg-white border-b">
          <div className="container mx-auto px-4 py-8">
            <div className="flex flex-wrap justify-center items-center gap-8 text-gray-600">
              <div className="flex items-center">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <span>100% Free</span>
              </div>
              <div className="flex items-center">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
                <span>Secure & Private</span>
              </div>
              <div className="flex items-center">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
                </svg>
                <span>Instant Download</span>
              </div>
              <div className="flex items-center">
                <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"></path>
                </svg>
                <span>4.8/5 Rating</span>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-16">
          <div className="max-w-4xl mx-auto">
            {/* Benefits Section with Cards */}
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {benefits.map((benefit, index) => {
                const Icon = benefit.icon;
                return (
                  <div key={index} className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
                    <div className="p-8">
                      <div className={`w-14 h-14 rounded-full flex items-center justify-center mb-6 ${benefit.color} bg-opacity-10`}>
                        <Icon className="w-8 h-8" style={{ color: benefit.color }} />
                      </div>
                      <h3 className="text-xl font-bold text-gray-900 mb-3">{benefit.title}</h3>
                      <p className="text-gray-600 leading-relaxed">{benefit.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            {/* Features Section with Modern Design */}
            <section id="features" className="mb-16 scroll-mt-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                Key Features of Our {capitalizedIndustry} {capitalizedDocType} Generator
              </h2>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-xl font-bold mb-4 text-indigo-600">Industry-Specific Templates</h3>
                  <p className="text-gray-600 leading-relaxed">Access professionally designed templates tailored specifically for {capitalizedIndustry.toLowerCase()} businesses. Each template is crafted to meet industry standards and best practices.</p>
                </div>
                <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-xl font-bold mb-4 text-indigo-600">Easy Customization</h3>
                  <p className="text-gray-600 leading-relaxed">Customize your {documentType.toLowerCase()} with your business details, logo, and branding. Add, remove, or modify sections to match your specific needs.</p>
                </div>
                <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-xl font-bold mb-4 text-indigo-600">Professional Format</h3>
                  <p className="text-gray-600 leading-relaxed">Create polished, professional-looking {documentType.toLowerCase()}s that enhance your business image and build trust with clients.</p>
                </div>
                <div className="bg-white rounded-xl shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-xl font-bold mb-4 text-indigo-600">Instant Generation</h3>
                  <p className="text-gray-600 leading-relaxed">Generate your {documentType.toLowerCase()} in seconds. Save time and focus on growing your {capitalizedIndustry.toLowerCase()} business.</p>
                </div>
              </div>
            </section>

            {/* Why Choose Section with Visual Appeal */}
            <section className="mb-16 bg-gradient-to-r from-gray-50 to-gray-100 rounded-2xl p-8">
              <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">
                Why Choose Our {capitalizedDocType} Generator?
              </h2>
              <div className="prose max-w-none text-gray-600">
                <p className="text-lg mb-6">Running a successful {capitalizedIndustry.toLowerCase()} business requires professional documentation that reflects the quality of your services. Our {documentType.toLowerCase()} generator is specifically designed to meet the unique needs of {capitalizedIndustry.toLowerCase()} professionals.</p>
                <div className="grid md:grid-cols-2 gap-6">
                  <ul className="list-none space-y-4">
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Save time with ready-to-use templates
                    </li>
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Maintain consistency across documents
                    </li>
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Look professional with modern layouts
                    </li>
                  </ul>
                  <ul className="list-none space-y-4">
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Access industry-specific terms
                    </li>
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Generate unlimited documents free
                    </li>
                    <li className="flex items-center text-gray-700">
                      <svg className="w-6 h-6 text-green-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                      Get instant digital delivery
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            {/* FAQ Section with Accordion Style */}
            <section className="mb-16">
              <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Frequently Asked Questions</h2>
              <div className="space-y-6">
                <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                  <div className="p-6">
                    <h3 className="text-xl font-bold mb-4 text-indigo-600">How do I create a {documentType.toLowerCase()} for my {capitalizedIndustry.toLowerCase()} business?</h3>
                    <p className="text-gray-600">Simply click the "Create {capitalizedDocType} Now" button, choose a template, and fill in your business details. Our generator will create a professional {documentType.toLowerCase()} in minutes.</p>
                  </div>
                </div>
                <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                  <div className="p-6">
                    <h3 className="text-xl font-bold mb-4 text-indigo-600">Is this {documentType.toLowerCase()} generator free?</h3>
                    <p className="text-gray-600">Yes, our {documentType.toLowerCase()} generator is completely free to use. Create unlimited {documentType.toLowerCase()}s for your {capitalizedIndustry.toLowerCase()} business without any cost.</p>
                  </div>
                </div>
                <div className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
                  <div className="p-6">
                    <h3 className="text-xl font-bold mb-4 text-indigo-600">Can I customize the {documentType.toLowerCase()} template?</h3>
                    <p className="text-gray-600">Absolutely! Our templates are fully customizable. Add your logo, adjust colors, modify sections, and make it truly yours.</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Testimonials Section */}
            <section className="bg-gray-50 py-16">
              <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-gray-900 mb-12 text-center">What Our Users Say</h2>
                <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                  <div className="bg-white rounded-xl shadow-lg p-8">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0">
                        <div className="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center">
                          <span className="text-xl font-bold text-indigo-600">J</span>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-semibold">John D.</h4>
                        <div className="flex text-yellow-400">
                          {'★'.repeat(5)}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600">Perfect for my {capitalizedIndustry.toLowerCase()} business. Creates professional {documentType.toLowerCase()}s in minutes!</p>
                  </div>
                  <div className="bg-white rounded-xl shadow-lg p-8">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0">
                        <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
                          <span className="text-xl font-bold text-purple-600">S</span>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-semibold">Sarah M.</h4>
                        <div className="flex text-yellow-400">
                          {'★'.repeat(5)}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600">Easy to use and looks very professional. Saved me hours of work!</p>
                  </div>
                  <div className="bg-white rounded-xl shadow-lg p-8">
                    <div className="flex items-center mb-4">
                      <div className="flex-shrink-0">
                        <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center">
                          <span className="text-xl font-bold text-green-600">R</span>
                        </div>
                      </div>
                      <div className="ml-4">
                        <h4 className="text-lg font-semibold">Robert K.</h4>
                        <div className="flex text-yellow-400">
                          {'★'.repeat(5)}
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-600">The industry-specific templates are exactly what I needed. Highly recommend!</p>
                  </div>
                </div>
              </div>
            </section>

            {/* Floating CTA Button */}
            <div className="fixed bottom-8 right-8 z-50 animate-bounce">
              <Link
                to={`/${documentType}/generate`}
                className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-200"
              >
                Create {capitalizedDocType} Now
                <ArrowForward className="ml-2 -mr-1 h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}