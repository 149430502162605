import React from 'react';
import { Container, Box, Paper, Typography, SvgIconProps } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(12),
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 4px 24px rgba(0, 0, 0, 0.05)',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4),
  },
}));

interface CalculatorLayoutProps {
  title: string;
  description: string;
  icon: React.ComponentType<SvgIconProps>;
  inputSection: React.ReactNode;
  resultSection?: React.ReactNode;
  helpSection?: React.ReactNode;
}

export default function CalculatorLayout({
  title,
  description,
  icon: Icon,
  inputSection,
  resultSection,
  helpSection,
}: CalculatorLayoutProps) {
  return (
    <Box className="min-h-screen bg-gradient-to-br from-slate-50 to-white">
      <StyledContainer maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <Box className="flex items-center justify-center gap-3 mb-8">
            <Box className="flex h-16 w-16 items-center justify-center rounded-2xl bg-red-600">
              <Icon className="h-8 w-8 text-white" />
            </Box>
          </Box>
          <Typography variant="h3" component="h1" className="text-slate-800 mb-4">
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            className="text-slate-600 max-w-2xl mx-auto"
          >
            {description}
          </Typography>
        </motion.div>

        <Box className="mt-12 grid gap-8 md:grid-cols-12">
          {/* Main Calculator Section */}
          <Box className="md:col-span-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <StyledPaper>
                {inputSection}
              </StyledPaper>

              {resultSection && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <StyledPaper>
                    {resultSection}
                  </StyledPaper>
                </motion.div>
              )}
            </motion.div>
          </Box>

          {/* Help Section */}
          {helpSection && (
            <Box className="md:col-span-4">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StyledPaper>
                  <Typography variant="h6" gutterBottom>
                    Help & Information
                  </Typography>
                  {helpSection}
                </StyledPaper>
              </motion.div>
            </Box>
          )}
        </Box>
      </StyledContainer>
    </Box>
  );
}
