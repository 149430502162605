import { Receipt, Bolt, AccessTime, AutoGraph, PieChart, Shield, CameraAlt, People, Business } from '@mui/icons-material';

export interface SubCategory {
  slug: string;
  name: string;
  description?: string;
}

export interface Industry {
  slug: string;
  name: string;
  description: string;
  subcategories?: SubCategory[];
  benefits: Array<{
    title: string;
    description: string;
    icon: any;
    color: string;
  }>;
}

export const industries: Industry[] = [
  {
    slug: 'photographers',
    name: 'Photographers',
    description: 'Professional photography services with customized document templates.',
    subcategories: [
      // Original categories
      { 
        slug: 'photographers-portrait',
        name: 'Portrait Photography',
        description: 'Capture stunning portraits with professional document templates designed for portrait photographers.'
      },
      { 
        slug: 'photographers-wedding',
        name: 'Wedding Photography',
        description: 'Streamline your wedding photography business with professional contracts and invoices.'
      },
      { 
        slug: 'photographers-commercial',
        name: 'Commercial Photography',
        description: 'Professional documents for commercial and advertising photography projects.'
      },
      // Additional subcategories from URLs
      {
        slug: 'photographers-360',
        name: '360° Photography',
        description: 'Professional 360-degree photography services and virtual tours.'
      },
      {
        slug: 'photographers-abstract',
        name: 'Abstract Photography',
        description: 'Creative abstract photography services and artistic documentation.'
      },
      {
        slug: 'photographers-advertising',
        name: 'Advertising Photography',
        description: 'Professional advertising and commercial photography services.'
      },
      {
        slug: 'photographers-aerial',
        name: 'Aerial Photography',
        description: 'Drone and aerial photography services with professional documentation.'
      },
      {
        slug: 'photographers-architectural',
        name: 'Architectural Photography',
        description: 'Professional architectural and interior photography services.'
      },
      {
        slug: 'photographers-boudoir',
        name: 'Boudoir Photography',
        description: 'Professional boudoir photography services with discrete documentation.'
      },
      {
        slug: 'photographers-composite',
        name: 'Composite Photography',
        description: 'Creative composite and manipulation photography services.'
      },
      {
        slug: 'photographers-concert',
        name: 'Concert Photography',
        description: 'Professional concert and live music event photography.'
      },
      {
        slug: 'photographers-corporate',
        name: 'Corporate Photography',
        description: 'Professional corporate and business photography services.'
      },
      {
        slug: 'photographers-documentary',
        name: 'Documentary Photography',
        description: 'Professional documentary and photojournalistic services.'
      },
      {
        slug: 'photographers-drone',
        name: 'Drone Photography',
        description: 'Professional drone and aerial imaging services.'
      },
      {
        slug: 'photographers-editorial',
        name: 'Editorial Photography',
        description: 'Professional editorial and publication photography.'
      },
      {
        slug: 'photographers-event',
        name: 'Event Photography',
        description: 'Professional event coverage and documentation services.'
      },
      {
        slug: 'photographers-family',
        name: 'Family Photography',
        description: 'Professional family portrait and lifestyle photography.'
      },
      {
        slug: 'photographers-fashion',
        name: 'Fashion Photography',
        description: 'Professional fashion and model photography services.'
      },
      {
        slug: 'photographers-fine-art',
        name: 'Fine Art Photography',
        description: 'Professional fine art photography and prints.'
      },
      {
        slug: 'photographers-food',
        name: 'Food Photography',
        description: 'Professional food and culinary photography services.'
      },
      {
        slug: 'photographers-graduation',
        name: 'Graduation Photography',
        description: 'Professional graduation and academic event photography.'
      },
      {
        slug: 'photographers-headshot',
        name: 'Headshot Photography',
        description: 'Professional headshot and portrait photography services.'
      },
      {
        slug: 'photographers-landscape',
        name: 'Landscape Photography',
        description: 'Professional landscape and nature photography services.'
      },
      {
        slug: 'photographers-lifestyle',
        name: 'Lifestyle Photography',
        description: 'Professional lifestyle and environmental photography.'
      },
      {
        slug: 'photographers-macro',
        name: 'Macro Photography',
        description: 'Professional macro and close-up photography services.'
      },
      {
        slug: 'photographers-maternity',
        name: 'Maternity Photography',
        description: 'Professional maternity and pregnancy photography.'
      },
      {
        slug: 'photographers-newborn',
        name: 'Newborn Photography',
        description: 'Professional newborn and baby photography services.'
      },
      {
        slug: 'photographers-night',
        name: 'Night Photography',
        description: 'Professional night and low-light photography services.'
      },
      {
        slug: 'photographers-pet',
        name: 'Pet Photography',
        description: 'Professional pet and animal photography services.'
      },
      {
        slug: 'photographers-photojournalist',
        name: 'Photojournalist',
        description: 'Professional photojournalism and news photography services.'
      },
      {
        slug: 'photographers-press',
        name: 'Press Photography',
        description: 'Professional press and media photography services.'
      },
      {
        slug: 'photographers-product',
        name: 'Product Photography',
        description: 'Professional product and e-commerce photography.'
      },
      {
        slug: 'photographers-real-estate',
        name: 'Real Estate Photography',
        description: 'Professional real estate and property photography.'
      },
      {
        slug: 'photographers-school',
        name: 'School Photography',
        description: 'Professional school and education photography services.'
      },
      {
        slug: 'photographers-social-media',
        name: 'Social Media Photography',
        description: 'Professional social media content photography.'
      },
      {
        slug: 'photographers-sports',
        name: 'Sports Photography',
        description: 'Professional sports and action photography services.'
      },
      {
        slug: 'photographers-stock',
        name: 'Stock Photography',
        description: 'Professional stock photography services and licensing.'
      },
      {
        slug: 'photographers-street',
        name: 'Street Photography',
        description: 'Professional street and urban photography services.'
      },
      {
        slug: 'photographers-studio',
        name: 'Studio Photography',
        description: 'Professional studio photography and controlled lighting services.'
      },
      {
        slug: 'photographers-timelapse',
        name: 'Timelapse Photography',
        description: 'Professional timelapse photography services.'
      },
      {
        slug: 'photographers-travel',
        name: 'Travel Photography',
        description: 'Professional travel and destination photography services.'
      },
      {
        slug: 'photographers-underwater',
        name: 'Underwater Photography',
        description: 'Professional underwater and aquatic photography.'
      },
      {
        slug: 'photographers-virtual-tour',
        name: 'Virtual Tour Photography',
        description: 'Professional virtual tour and 360° property photography.'
      },
      {
        slug: 'photographers-wildlife',
        name: 'Wildlife Photography',
        description: 'Professional wildlife and nature photography services.'
      }
    ],
    benefits: [
      {
        title: 'Professional Templates',
        description: 'Access photography-specific templates for contracts, invoices, and more.',
        icon: CameraAlt,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Management',
        description: 'Streamline your client communication with professional documents.',
        icon: People,
        color: 'bg-green-100 text-green-600'
      },
      {
        title: 'Business Growth',
        description: 'Scale your photography business with organized documentation.',
        icon: Business,
        color: 'bg-purple-100 text-purple-600'
      }
    ]
  },
  {
    slug: 'real-estate-agents',
    name: 'Real Estate Agents',
    description: 'Real estate professionals and agencies',
    benefits: [
      {
        title: 'Real Estate Templates',
        description: 'Templates customized for real estate transactions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Instant Generation',
        description: 'Generate documents in seconds between showings',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Property Terms',
        description: 'Built-in real estate terminology and clauses',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'consultants',
    name: 'Consultants',
    description: 'Business and professional consultants',
    benefits: [
      {
        title: 'Consulting Templates',
        description: 'Professional templates for consulting services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Time-Saving',
        description: 'Focus on clients, not paperwork',
        icon: AccessTime,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Track Success',
        description: 'Monitor proposal and invoice performance',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'marketing-agencies',
    name: 'Marketing Agencies',
    description: 'Digital and traditional marketing services',
    benefits: [
      {
        title: 'Marketing Templates',
        description: 'Templates designed for marketing campaigns and services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Turnaround',
        description: 'Create documents for clients in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Campaign Tracking',
        description: 'Monitor project progress and deliverables',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'software-developers',
    name: 'Software Developers',
    description: 'Software development and IT services',
    benefits: [
      {
        title: 'Tech Templates',
        description: 'Templates tailored for software projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Agile Ready',
        description: 'Perfect for sprint-based project management',
        icon: AccessTime,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor development milestones and deliverables',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'healthcare-providers',
    name: 'Healthcare Providers',
    description: 'Medical practices and healthcare services',
    benefits: [
      {
        title: 'Healthcare Templates',
        description: 'HIPAA-compliant document templates',
        icon: Shield,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Instant Documents',
        description: 'Generate patient documents quickly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Secure Forms',
        description: 'Protected health information handling',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'architects',
    name: 'Architects',
    description: 'Architectural design and planning services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for architectural projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Proposals',
        description: 'Create project proposals in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Terms',
        description: 'Built-in architectural terminology',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'lawyers',
    name: 'Lawyers',
    description: 'Legal services and law practices',
    benefits: [
      {
        title: 'Legal Templates',
        description: 'Professional legal document templates',
        icon: Shield,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Time Tracking',
        description: 'Built-in billable hours tracking',
        icon: AccessTime,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Case Management',
        description: 'Organize documents by case or client',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'interior-designers',
    name: 'Interior Designers',
    description: 'Interior design and decoration services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for design projects and quotes',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate project quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design projects and timelines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'event-planners',
    name: 'Event Planners',
    description: 'Event planning and coordination services',
    benefits: [
      {
        title: 'Event Templates',
        description: 'Templates for event proposals and contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create event quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Tracking',
        description: 'Monitor event milestones and deadlines',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'personal-trainers',
    name: 'Personal Trainers',
    description: 'Fitness training and coaching services',
    benefits: [
      {
        title: 'Fitness Templates',
        description: 'Templates for training programs and contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Plans',
        description: 'Create training plans instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor client progress and goals',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'accountants',
    name: 'Accountants',
    description: 'Accounting and financial services',
    benefits: [
      {
        title: 'Financial Templates',
        description: 'Templates for financial reports and statements',
        icon: Receipt,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate financial documents instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Tracking',
        description: 'Monitor client accounts and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'wedding-planners',
    name: 'Wedding Planners',
    description: 'Wedding planning and coordination services',
    benefits: [
      {
        title: 'Wedding Templates',
        description: 'Templates for wedding planning documents',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create wedding quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Tracking',
        description: 'Monitor wedding planning milestones',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  }
  ,{
    slug: 'teachers',
    name: 'Teachers',
    description: 'Educational services and tutoring',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for lesson plans and assessments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Planning',
        description: 'Create educational documents instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Progress Reports',
        description: 'Track student progress and achievements',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'contractors',
    name: 'Contractors',
    description: 'Construction and renovation services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for construction projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Management',
        description: 'Track construction milestones',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'graphic-designers',
    name: 'Graphic Designers',
    description: 'Visual design and branding services',
    benefits: [
      {
        title: 'Design Proposals',
        description: 'Templates for design project proposals',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create design quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design revisions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'nutritionists',
    name: 'Nutritionists',
    description: 'Dietary and nutrition consulting services',
    benefits: [
      {
        title: 'Meal Plan Templates',
        description: 'Professional meal planning documents',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track dietary goals and achievements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate nutrition reports instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'life-coaches',
    name: 'Life Coaches',
    description: 'Personal development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for coaching programs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Goal Tracking',
        description: 'Monitor client goals and progress',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Planning',
        description: 'Organize coaching sessions efficiently',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'videographers',
    name: 'Videographers',
    description: 'Video production and editing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for video production contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create video project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Production Tracking',
        description: 'Monitor video project milestones',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'makeup-artists',
    name: 'Makeup Artists',
    description: 'Professional makeup and beauty services',
    benefits: [
      {
        title: 'Beauty Templates',
        description: 'Templates for makeup service contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate booking confirmations instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Management',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dance-instructors',
    name: 'Dance Instructors',
    description: 'Dance education and choreography services',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for dance class plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Progress',
        description: 'Track student development and skills',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Schedule Management',
        description: 'Organize classes and performances',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'music-teachers',
    name: 'Music Teachers',
    description: 'Music education and instrument instruction',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for music lesson plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Tracking',
        description: 'Monitor student progress and practice',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Recital Planning',
        description: 'Organize performances and events',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'pet-groomers',
    name: 'Pet Groomers',
    description: 'Professional pet grooming services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for grooming services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointments instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet histories and preferences',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {    
    slug: 'tattoo-artists',
    name: 'Tattoo Artists',
    description: 'Professional tattoo and body art services',
    benefits: [
      {
        title: 'Design Contracts',
        description: 'Templates for tattoo agreements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate price quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Records',
        description: 'Track client designs and sessions',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dentists',
    name: 'Dentists',
    description: 'Dental care and oral health services',
    benefits: [
      {
        title: 'Dental Templates',
        description: 'Templates for dental procedures and treatments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track patient treatments and appointments',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Billing',
        description: 'Generate dental bills and insurance forms',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'chiropractors',
    name: 'Chiropractors',
    description: 'Chiropractic care and wellness services',
    benefits: [
      {
        title: 'Treatment Plans',
        description: 'Templates for chiropractic care plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Progress',
        description: 'Track treatment progress and outcomes',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'massage-therapists',
    name: 'Massage Therapists',
    description: 'Therapeutic massage and bodywork services',
    benefits: [
      {
        title: 'Session Templates',
        description: 'Templates for massage sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Notes',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointment confirmations',
        icon: Bolt,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'psychologists',
    name: 'Psychologists',
    description: 'Mental health and psychological services',
    benefits: [
      {
        title: 'Session Notes',
        description: 'Templates for therapy session documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track therapeutic progress and goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Secure Records',
        description: 'HIPAA-compliant documentation',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'optometrists',
    name: 'Optometrists',
    description: 'Eye care and vision services',
    benefits: [
      {
        title: 'Eye Care Templates',
        description: 'Templates for vision care documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track vision prescriptions and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate eye exam reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'physical-therapists',
    name: 'Physical Therapists',
    description: 'Physical therapy and rehabilitation services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for PT treatment plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor patient recovery goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Exercise Plans',
        description: 'Generate home exercise programs',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'veterinarians',
    name: 'Veterinarians',
    description: 'Veterinary care and animal health services',
    benefits: [
      {
        title: 'Vet Templates',
        description: 'Templates for veterinary care',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet health history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment summaries instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'speech-therapists',
    name: 'Speech Therapists',
    description: 'Speech and language therapy services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for speech therapy sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Notes',
        description: 'Track communication improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Plans',
        description: 'Generate therapy exercise plans',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'occupational-therapists',
    name: 'Occupational Therapists',
    description: 'Occupational therapy and rehabilitation services',
    benefits: [
      {
        title: 'OT Templates',
        description: 'Templates for occupational therapy',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor functional improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Treatment Plans',
        description: 'Generate therapy programs instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'web-developers',
    name: 'Web Developers',
    description: 'Web development and design services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for web development projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Milestone Tracking',
        description: 'Monitor development progress',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'copywriters',
    name: 'Copywriters',
    description: 'Professional writing and content creation',
    benefits: [
      {
        title: 'Writing Templates',
        description: 'Templates for content projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor content milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate writing quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'social-media-managers',
    name: 'Social Media Managers',
    description: 'Social media marketing and management',
    benefits: [
      {
        title: 'Campaign Templates',
        description: 'Templates for social media campaigns',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Content Calendar',
        description: 'Track posting schedules and deadlines',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Performance Reports',
        description: 'Generate campaign analytics reports',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'ui-designers',
    name: 'UI Designers',
    description: 'User interface design services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for UI design projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Proposals',
        description: 'Generate design proposals instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design iterations and feedback',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'florists',
    name: 'Florists',
    description: 'Floral design and arrangement services',
    benefits: [
      {
        title: 'Order Templates',
        description: 'Templates for floral arrangements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate flower arrangement quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track floral event schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'career-coaches',
    name: 'Career Coaches',
    description: 'Career development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for career coaching sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor client career goals',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Session Notes',
        description: 'Generate coaching summaries instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'business-consultants',
    name: 'Business Consultants',
    description: 'Business strategy and optimization services',
    benefits: [
      {
        title: 'Strategy Templates',
        description: 'Templates for business consulting',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor consulting milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate business analysis reports',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'fitness-instructors',
    name: 'Fitness Instructors',
    description: 'Group fitness and exercise instruction',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for fitness classes',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track class schedules and attendance',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor class performance metrics',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'landscapers',
    name: 'Landscapers',
    description: 'Landscape design and maintenance services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for landscaping projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate landscaping quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor landscape maintenance schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'caterers',
    name: 'Caterers',
    description: 'Professional food service and event catering',
    benefits: [
      {
        title: 'Menu Templates',
        description: 'Templates for catering menus and events',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track catering events and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate catering quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'electricians',
    name: 'Electricians',
    description: 'Electrical installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for electrical services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate electrical quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor electrical projects and schedules',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'plumbers',
    name: 'Plumbers',
    description: 'Plumbing installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for plumbing services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate plumbing estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Management',
        description: 'Track plumbing projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'hvac-technicians',
    name: 'HVAC Technicians',
    description: 'Heating, ventilation, and air conditioning services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for HVAC maintenance',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate HVAC service quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor HVAC system schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'auto-mechanics',
    name: 'Auto Mechanics',
    description: 'Automotive repair and maintenance services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for auto repairs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate repair quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Repair Tracking',
        description: 'Monitor vehicle repairs and maintenance',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'painters',
    name: 'Painters',
    description: 'Professional painting and finishing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for painting projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate painting estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor painting projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'carpet-cleaners',
    name: 'Carpet Cleaners',
    description: 'Professional carpet and upholstery cleaning',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for cleaning services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate cleaning estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track cleaning appointments',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'voice-actors',
    name: 'Voice Actors',
    description: 'Professional voice-over and narration services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for voice-over projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate voice work estimates',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor recording sessions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'translators',
    name: 'Translators',
    description: 'Professional translation and interpretation services',
    benefits: [
      {
        title: 'Translation Templates',
        description: 'Templates for translation projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor translation progress',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate translation estimates instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'tour-guides',
    name: 'Tour Guides',
    description: 'Professional tour and travel guide services',
    benefits: [
      {
        title: 'Tour Templates',
        description: 'Templates for tour itineraries',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track tour bookings and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate tour pricing instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  }
  ,{
    slug: 'teachers',
    name: 'Teachers',
    description: 'Educational services and tutoring',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for lesson plans and assessments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Planning',
        description: 'Create educational documents instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Progress Reports',
        description: 'Track student progress and achievements',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'contractors',
    name: 'Contractors',
    description: 'Construction and renovation services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for construction projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Management',
        description: 'Track construction milestones',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'graphic-designers',
    name: 'Graphic Designers',
    description: 'Visual design and branding services',
    benefits: [
      {
        title: 'Design Proposals',
        description: 'Templates for design project proposals',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create design quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design revisions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'nutritionists',
    name: 'Nutritionists',
    description: 'Dietary and nutrition consulting services',
    benefits: [
      {
        title: 'Meal Plan Templates',
        description: 'Professional meal planning documents',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track dietary goals and achievements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate nutrition reports instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'life-coaches',
    name: 'Life Coaches',
    description: 'Personal development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for coaching programs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Goal Tracking',
        description: 'Monitor client goals and progress',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Planning',
        description: 'Organize coaching sessions efficiently',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'videographers',
    name: 'Videographers',
    description: 'Video production and editing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for video production contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create video project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Production Tracking',
        description: 'Monitor video project milestones',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'makeup-artists',
    name: 'Makeup Artists',
    description: 'Professional makeup and beauty services',
    benefits: [
      {
        title: 'Beauty Templates',
        description: 'Templates for makeup service contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate booking confirmations instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Management',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dance-instructors',
    name: 'Dance Instructors',
    description: 'Dance education and choreography services',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for dance class plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Progress',
        description: 'Track student development and skills',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Schedule Management',
        description: 'Organize classes and performances',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'music-teachers',
    name: 'Music Teachers',
    description: 'Music education and instrument instruction',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for music lesson plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Tracking',
        description: 'Monitor student progress and practice',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Recital Planning',
        description: 'Organize performances and events',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'pet-groomers',
    name: 'Pet Groomers',
    description: 'Professional pet grooming services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for grooming services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointments instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet histories and preferences',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {    
    slug: 'tattoo-artists',
    name: 'Tattoo Artists',
    description: 'Professional tattoo and body art services',
    benefits: [
      {
        title: 'Design Contracts',
        description: 'Templates for tattoo agreements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate price quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Records',
        description: 'Track client designs and sessions',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dentists',
    name: 'Dentists',
    description: 'Dental care and oral health services',
    benefits: [
      {
        title: 'Dental Templates',
        description: 'Templates for dental procedures and treatments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track patient treatments and appointments',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Billing',
        description: 'Generate dental bills and insurance forms',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'chiropractors',
    name: 'Chiropractors',
    description: 'Chiropractic care and wellness services',
    benefits: [
      {
        title: 'Treatment Plans',
        description: 'Templates for chiropractic care plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Progress',
        description: 'Track treatment progress and outcomes',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'massage-therapists',
    name: 'Massage Therapists',
    description: 'Therapeutic massage and bodywork services',
    benefits: [
      {
        title: 'Session Templates',
        description: 'Templates for massage sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Notes',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointment confirmations',
        icon: Bolt,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'psychologists',
    name: 'Psychologists',
    description: 'Mental health and psychological services',
    benefits: [
      {
        title: 'Session Notes',
        description: 'Templates for therapy session documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track therapeutic progress and goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Secure Records',
        description: 'HIPAA-compliant documentation',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'optometrists',
    name: 'Optometrists',
    description: 'Eye care and vision services',
    benefits: [
      {
        title: 'Eye Care Templates',
        description: 'Templates for vision care documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track vision prescriptions and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate eye exam reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'physical-therapists',
    name: 'Physical Therapists',
    description: 'Physical therapy and rehabilitation services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for PT treatment plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor patient recovery goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Exercise Plans',
        description: 'Generate home exercise programs',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'veterinarians',
    name: 'Veterinarians',
    description: 'Veterinary care and animal health services',
    benefits: [
      {
        title: 'Vet Templates',
        description: 'Templates for veterinary care',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet health history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment summaries instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'speech-therapists',
    name: 'Speech Therapists',
    description: 'Speech and language therapy services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for speech therapy sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Notes',
        description: 'Track communication improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Plans',
        description: 'Generate therapy exercise plans',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'occupational-therapists',
    name: 'Occupational Therapists',
    description: 'Occupational therapy and rehabilitation services',
    benefits: [
      {
        title: 'OT Templates',
        description: 'Templates for occupational therapy',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor functional improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Treatment Plans',
        description: 'Generate therapy programs instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'web-developers',
    name: 'Web Developers',
    description: 'Web development and design services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for web development projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Milestone Tracking',
        description: 'Monitor development progress',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'copywriters',
    name: 'Copywriters',
    description: 'Professional writing and content creation',
    benefits: [
      {
        title: 'Writing Templates',
        description: 'Templates for content projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor content milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate writing quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'social-media-managers',
    name: 'Social Media Managers',
    description: 'Social media marketing and management',
    benefits: [
      {
        title: 'Campaign Templates',
        description: 'Templates for social media campaigns',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Content Calendar',
        description: 'Track posting schedules and deadlines',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Performance Reports',
        description: 'Generate campaign analytics reports',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'ui-designers',
    name: 'UI Designers',
    description: 'User interface design services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for UI design projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Proposals',
        description: 'Generate design proposals instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design iterations and feedback',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'florists',
    name: 'Florists',
    description: 'Floral design and arrangement services',
    benefits: [
      {
        title: 'Order Templates',
        description: 'Templates for floral arrangements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate flower arrangement quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track floral event schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'career-coaches',
    name: 'Career Coaches',
    description: 'Career development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for career coaching sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor client career goals',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Session Notes',
        description: 'Generate coaching summaries instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'business-consultants',
    name: 'Business Consultants',
    description: 'Business strategy and optimization services',
    benefits: [
      {
        title: 'Strategy Templates',
        description: 'Templates for business consulting',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor consulting milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate business analysis reports',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'fitness-instructors',
    name: 'Fitness Instructors',
    description: 'Group fitness and exercise instruction',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for fitness classes',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track class schedules and attendance',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor class performance metrics',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'landscapers',
    name: 'Landscapers',
    description: 'Landscape design and maintenance services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for landscaping projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate landscaping quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor landscape maintenance schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'caterers',
    name: 'Caterers',
    description: 'Professional food service and event catering',
    benefits: [
      {
        title: 'Menu Templates',
        description: 'Templates for catering menus and events',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track catering events and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate catering quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'electricians',
    name: 'Electricians',
    description: 'Electrical installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for electrical services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate electrical quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor electrical projects and schedules',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'plumbers',
    name: 'Plumbers',
    description: 'Plumbing installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for plumbing services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate plumbing estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Management',
        description: 'Track plumbing projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'hvac-technicians',
    name: 'HVAC Technicians',
    description: 'Heating, ventilation, and air conditioning services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for HVAC maintenance',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate HVAC service quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor HVAC system schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'auto-mechanics',
    name: 'Auto Mechanics',
    description: 'Automotive repair and maintenance services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for auto repairs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate repair quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Repair Tracking',
        description: 'Monitor vehicle repairs and maintenance',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'painters',
    name: 'Painters',
    description: 'Professional painting and finishing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for painting projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate painting estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor painting projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'carpet-cleaners',
    name: 'Carpet Cleaners',
    description: 'Professional carpet and upholstery cleaning',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for cleaning services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate cleaning estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track cleaning appointments',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'voice-actors',
    name: 'Voice Actors',
    description: 'Professional voice-over and narration services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for voice-over projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate voice work estimates',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor recording sessions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'translators',
    name: 'Translators',
    description: 'Professional translation and interpretation services',
    benefits: [
      {
        title: 'Translation Templates',
        description: 'Templates for translation projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor translation progress',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate translation estimates instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'tour-guides',
    name: 'Tour Guides',
    description: 'Professional tour and travel guide services',
    benefits: [
      {
        title: 'Tour Templates',
        description: 'Templates for tour itineraries',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track tour bookings and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate tour pricing instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  }
  ,{
    slug: 'teachers',
    name: 'Teachers',
    description: 'Educational services and tutoring',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for lesson plans and assessments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Planning',
        description: 'Create educational documents instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Progress Reports',
        description: 'Track student progress and achievements',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'contractors',
    name: 'Contractors',
    description: 'Construction and renovation services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for construction projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Management',
        description: 'Track construction milestones',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'graphic-designers',
    name: 'Graphic Designers',
    description: 'Visual design and branding services',
    benefits: [
      {
        title: 'Design Proposals',
        description: 'Templates for design project proposals',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create design quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design revisions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'nutritionists',
    name: 'Nutritionists',
    description: 'Dietary and nutrition consulting services',
    benefits: [
      {
        title: 'Meal Plan Templates',
        description: 'Professional meal planning documents',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track dietary goals and achievements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate nutrition reports instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'life-coaches',
    name: 'Life Coaches',
    description: 'Personal development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for coaching programs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Goal Tracking',
        description: 'Monitor client goals and progress',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Planning',
        description: 'Organize coaching sessions efficiently',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'videographers',
    name: 'Videographers',
    description: 'Video production and editing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for video production contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create video project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Production Tracking',
        description: 'Monitor video project milestones',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'makeup-artists',
    name: 'Makeup Artists',
    description: 'Professional makeup and beauty services',
    benefits: [
      {
        title: 'Beauty Templates',
        description: 'Templates for makeup service contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate booking confirmations instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Management',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dance-instructors',
    name: 'Dance Instructors',
    description: 'Dance education and choreography services',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for dance class plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Progress',
        description: 'Track student development and skills',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Schedule Management',
        description: 'Organize classes and performances',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'music-teachers',
    name: 'Music Teachers',
    description: 'Music education and instrument instruction',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for music lesson plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Tracking',
        description: 'Monitor student progress and practice',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Recital Planning',
        description: 'Organize performances and events',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'pet-groomers',
    name: 'Pet Groomers',
    description: 'Professional pet grooming services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for grooming services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointments instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet histories and preferences',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {    
    slug: 'tattoo-artists',
    name: 'Tattoo Artists',
    description: 'Professional tattoo and body art services',
    benefits: [
      {
        title: 'Design Contracts',
        description: 'Templates for tattoo agreements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate price quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Records',
        description: 'Track client designs and sessions',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dentists',
    name: 'Dentists',
    description: 'Dental care and oral health services',
    benefits: [
      {
        title: 'Dental Templates',
        description: 'Templates for dental procedures and treatments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track patient treatments and appointments',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Billing',
        description: 'Generate dental bills and insurance forms',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'chiropractors',
    name: 'Chiropractors',
    description: 'Chiropractic care and wellness services',
    benefits: [
      {
        title: 'Treatment Plans',
        description: 'Templates for chiropractic care plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Progress',
        description: 'Track treatment progress and outcomes',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'massage-therapists',
    name: 'Massage Therapists',
    description: 'Therapeutic massage and bodywork services',
    benefits: [
      {
        title: 'Session Templates',
        description: 'Templates for massage sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Notes',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointment confirmations',
        icon: Bolt,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'psychologists',
    name: 'Psychologists',
    description: 'Mental health and psychological services',
    benefits: [
      {
        title: 'Session Notes',
        description: 'Templates for therapy session documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track therapeutic progress and goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Secure Records',
        description: 'HIPAA-compliant documentation',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'optometrists',
    name: 'Optometrists',
    description: 'Eye care and vision services',
    benefits: [
      {
        title: 'Eye Care Templates',
        description: 'Templates for vision care documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track vision prescriptions and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate eye exam reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'physical-therapists',
    name: 'Physical Therapists',
    description: 'Physical therapy and rehabilitation services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for PT treatment plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor patient recovery goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Exercise Plans',
        description: 'Generate home exercise programs',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'veterinarians',
    name: 'Veterinarians',
    description: 'Veterinary care and animal health services',
    benefits: [
      {
        title: 'Vet Templates',
        description: 'Templates for veterinary care',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet health history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment summaries instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'speech-therapists',
    name: 'Speech Therapists',
    description: 'Speech and language therapy services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for speech therapy sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Notes',
        description: 'Track communication improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Plans',
        description: 'Generate therapy exercise plans',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'occupational-therapists',
    name: 'Occupational Therapists',
    description: 'Occupational therapy and rehabilitation services',
    benefits: [
      {
        title: 'OT Templates',
        description: 'Templates for occupational therapy',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor functional improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Treatment Plans',
        description: 'Generate therapy programs instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'web-developers',
    name: 'Web Developers',
    description: 'Web development and design services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for web development projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Milestone Tracking',
        description: 'Monitor development progress',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'copywriters',
    name: 'Copywriters',
    description: 'Professional writing and content creation',
    benefits: [
      {
        title: 'Writing Templates',
        description: 'Templates for content projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor content milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate writing quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'social-media-managers',
    name: 'Social Media Managers',
    description: 'Social media marketing and management',
    benefits: [
      {
        title: 'Campaign Templates',
        description: 'Templates for social media campaigns',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Content Calendar',
        description: 'Track posting schedules and deadlines',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Performance Reports',
        description: 'Generate campaign analytics reports',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'ui-designers',
    name: 'UI Designers',
    description: 'User interface design services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for UI design projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Proposals',
        description: 'Generate design proposals instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design iterations and feedback',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'florists',
    name: 'Florists',
    description: 'Floral design and arrangement services',
    benefits: [
      {
        title: 'Order Templates',
        description: 'Templates for floral arrangements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate flower arrangement quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track floral event schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'career-coaches',
    name: 'Career Coaches',
    description: 'Career development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for career coaching sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor client career goals',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Session Notes',
        description: 'Generate coaching summaries instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'business-consultants',
    name: 'Business Consultants',
    description: 'Business strategy and optimization services',
    benefits: [
      {
        title: 'Strategy Templates',
        description: 'Templates for business consulting',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor consulting milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate business analysis reports',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'fitness-instructors',
    name: 'Fitness Instructors',
    description: 'Group fitness and exercise instruction',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for fitness classes',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track class schedules and attendance',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor class performance metrics',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'landscapers',
    name: 'Landscapers',
    description: 'Landscape design and maintenance services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for landscaping projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate landscaping quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor landscape maintenance schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'caterers',
    name: 'Caterers',
    description: 'Professional food service and event catering',
    benefits: [
      {
        title: 'Menu Templates',
        description: 'Templates for catering menus and events',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track catering events and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate catering quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'electricians',
    name: 'Electricians',
    description: 'Electrical installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for electrical services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate electrical quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor electrical projects and schedules',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'plumbers',
    name: 'Plumbers',
    description: 'Plumbing installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for plumbing services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate plumbing estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Management',
        description: 'Track plumbing projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'hvac-technicians',
    name: 'HVAC Technicians',
    description: 'Heating, ventilation, and air conditioning services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for HVAC maintenance',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate HVAC service quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor HVAC system schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'auto-mechanics',
    name: 'Auto Mechanics',
    description: 'Automotive repair and maintenance services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for auto repairs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate repair quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Repair Tracking',
        description: 'Monitor vehicle repairs and maintenance',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'painters',
    name: 'Painters',
    description: 'Professional painting and finishing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for painting projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate painting estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor painting projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'carpet-cleaners',
    name: 'Carpet Cleaners',
    description: 'Professional carpet and upholstery cleaning',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for cleaning services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate cleaning estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track cleaning appointments',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'voice-actors',
    name: 'Voice Actors',
    description: 'Professional voice-over and narration services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for voice-over projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate voice work estimates',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor recording sessions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'translators',
    name: 'Translators',
    description: 'Professional translation and interpretation services',
    benefits: [
      {
        title: 'Translation Templates',
        description: 'Templates for translation projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor translation progress',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate translation estimates instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'tour-guides',
    name: 'Tour Guides',
    description: 'Professional tour and travel guide services',
    benefits: [
      {
        title: 'Tour Templates',
        description: 'Templates for tour itineraries',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track tour bookings and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate tour pricing instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  }
  ,{
    slug: 'teachers',
    name: 'Teachers',
    description: 'Educational services and tutoring',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for lesson plans and assessments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Planning',
        description: 'Create educational documents instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Progress Reports',
        description: 'Track student progress and achievements',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'contractors',
    name: 'Contractors',
    description: 'Construction and renovation services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for construction projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Timeline Management',
        description: 'Track construction milestones',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'graphic-designers',
    name: 'Graphic Designers',
    description: 'Visual design and branding services',
    benefits: [
      {
        title: 'Design Proposals',
        description: 'Templates for design project proposals',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create design quotes in minutes',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design revisions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'nutritionists',
    name: 'Nutritionists',
    description: 'Dietary and nutrition consulting services',
    benefits: [
      {
        title: 'Meal Plan Templates',
        description: 'Professional meal planning documents',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track dietary goals and achievements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate nutrition reports instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'life-coaches',
    name: 'Life Coaches',
    description: 'Personal development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for coaching programs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Goal Tracking',
        description: 'Monitor client goals and progress',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Planning',
        description: 'Organize coaching sessions efficiently',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'videographers',
    name: 'Videographers',
    description: 'Video production and editing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for video production contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Create video project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Production Tracking',
        description: 'Monitor video project milestones',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'makeup-artists',
    name: 'Makeup Artists',
    description: 'Professional makeup and beauty services',
    benefits: [
      {
        title: 'Beauty Templates',
        description: 'Templates for makeup service contracts',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate booking confirmations instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Management',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dance-instructors',
    name: 'Dance Instructors',
    description: 'Dance education and choreography services',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for dance class plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Progress',
        description: 'Track student development and skills',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Schedule Management',
        description: 'Organize classes and performances',
        icon: AccessTime,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'music-teachers',
    name: 'Music Teachers',
    description: 'Music education and instrument instruction',
    benefits: [
      {
        title: 'Lesson Templates',
        description: 'Templates for music lesson plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Student Tracking',
        description: 'Monitor student progress and practice',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Recital Planning',
        description: 'Organize performances and events',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'pet-groomers',
    name: 'Pet Groomers',
    description: 'Professional pet grooming services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for grooming services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointments instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet histories and preferences',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {    
    slug: 'tattoo-artists',
    name: 'Tattoo Artists',
    description: 'Professional tattoo and body art services',
    benefits: [
      {
        title: 'Design Contracts',
        description: 'Templates for tattoo agreements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate price quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Client Records',
        description: 'Track client designs and sessions',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'dentists',
    name: 'Dentists',
    description: 'Dental care and oral health services',
    benefits: [
      {
        title: 'Dental Templates',
        description: 'Templates for dental procedures and treatments',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track patient treatments and appointments',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Billing',
        description: 'Generate dental bills and insurance forms',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'chiropractors',
    name: 'Chiropractors',
    description: 'Chiropractic care and wellness services',
    benefits: [
      {
        title: 'Treatment Plans',
        description: 'Templates for chiropractic care plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Progress',
        description: 'Track treatment progress and outcomes',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'massage-therapists',
    name: 'Massage Therapists',
    description: 'Therapeutic massage and bodywork services',
    benefits: [
      {
        title: 'Session Templates',
        description: 'Templates for massage sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Notes',
        description: 'Track client preferences and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Booking',
        description: 'Generate appointment confirmations',
        icon: Bolt,
        color: 'bg-emerald-100 text-amber-600'
      }
    ]
  },
  {
    slug: 'psychologists',
    name: 'Psychologists',
    description: 'Mental health and psychological services',
    benefits: [
      {
        title: 'Session Notes',
        description: 'Templates for therapy session documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Client Progress',
        description: 'Track therapeutic progress and goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Secure Records',
        description: 'HIPAA-compliant documentation',
        icon: Shield,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'optometrists',
    name: 'Optometrists',
    description: 'Eye care and vision services',
    benefits: [
      {
        title: 'Eye Care Templates',
        description: 'Templates for vision care documentation',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Patient Records',
        description: 'Track vision prescriptions and history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate eye exam reports instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'physical-therapists',
    name: 'Physical Therapists',
    description: 'Physical therapy and rehabilitation services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for PT treatment plans',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor patient recovery goals',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Exercise Plans',
        description: 'Generate home exercise programs',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'veterinarians',
    name: 'Veterinarians',
    description: 'Veterinary care and animal health services',
    benefits: [
      {
        title: 'Vet Templates',
        description: 'Templates for veterinary care',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Pet Records',
        description: 'Track pet health history',
        icon: Receipt,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate treatment summaries instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'speech-therapists',
    name: 'Speech Therapists',
    description: 'Speech and language therapy services',
    benefits: [
      {
        title: 'Therapy Templates',
        description: 'Templates for speech therapy sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Notes',
        description: 'Track communication improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Session Plans',
        description: 'Generate therapy exercise plans',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'occupational-therapists',
    name: 'Occupational Therapists',
    description: 'Occupational therapy and rehabilitation services',
    benefits: [
      {
        title: 'OT Templates',
        description: 'Templates for occupational therapy',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor functional improvements',
        icon: PieChart,
        color: 'bg-amber-100 text-amber-600'
      },
      {
        title: 'Treatment Plans',
        description: 'Generate therapy programs instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'web-developers',
    name: 'Web Developers',
    description: 'Web development and design services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for web development projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate project quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Milestone Tracking',
        description: 'Monitor development progress',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'copywriters',
    name: 'Copywriters',
    description: 'Professional writing and content creation',
    benefits: [
      {
        title: 'Writing Templates',
        description: 'Templates for content projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor content milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate writing quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'social-media-managers',
    name: 'Social Media Managers',
    description: 'Social media marketing and management',
    benefits: [
      {
        title: 'Campaign Templates',
        description: 'Templates for social media campaigns',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Content Calendar',
        description: 'Track posting schedules and deadlines',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Performance Reports',
        description: 'Generate campaign analytics reports',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'ui-designers',
    name: 'UI Designers',
    description: 'User interface design services',
    benefits: [
      {
        title: 'Design Templates',
        description: 'Templates for UI design projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Proposals',
        description: 'Generate design proposals instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor design iterations and feedback',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'florists',
    name: 'Florists',
    description: 'Floral design and arrangement services',
    benefits: [
      {
        title: 'Order Templates',
        description: 'Templates for floral arrangements',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate flower arrangement quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track floral event schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'career-coaches',
    name: 'Career Coaches',
    description: 'Career development and coaching services',
    benefits: [
      {
        title: 'Coaching Templates',
        description: 'Templates for career coaching sessions',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor client career goals',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Session Notes',
        description: 'Generate coaching summaries instantly',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'business-consultants',
    name: 'Business Consultants',
    description: 'Business strategy and optimization services',
    benefits: [
      {
        title: 'Strategy Templates',
        description: 'Templates for business consulting',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor consulting milestones',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Reports',
        description: 'Generate business analysis reports',
        icon: Receipt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'fitness-instructors',
    name: 'Fitness Instructors',
    description: 'Group fitness and exercise instruction',
    benefits: [
      {
        title: 'Class Templates',
        description: 'Templates for fitness classes',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track class schedules and attendance',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Progress Tracking',
        description: 'Monitor class performance metrics',
        icon: PieChart,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'landscapers',
    name: 'Landscapers',
    description: 'Landscape design and maintenance services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for landscaping projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate landscaping quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor landscape maintenance schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'caterers',
    name: 'Caterers',
    description: 'Professional food service and event catering',
    benefits: [
      {
        title: 'Menu Templates',
        description: 'Templates for catering menus and events',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Event Planning',
        description: 'Track catering events and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate catering quotes instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-emerald-600'
      }
    ]
  },
  {
    slug: 'electricians',
    name: 'Electricians',
    description: 'Electrical installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for electrical services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate electrical quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor electrical projects and schedules',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'plumbers',
    name: 'Plumbers',
    description: 'Plumbing installation and repair services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for plumbing services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate plumbing estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Management',
        description: 'Track plumbing projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'hvac-technicians',
    name: 'HVAC Technicians',
    description: 'Heating, ventilation, and air conditioning services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for HVAC maintenance',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate HVAC service quotes',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Maintenance Tracking',
        description: 'Monitor HVAC system schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'auto-mechanics',
    name: 'Auto Mechanics',
    description: 'Automotive repair and maintenance services',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for auto repairs',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Estimates',
        description: 'Generate repair quotes instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Repair Tracking',
        description: 'Monitor vehicle repairs and maintenance',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'painters',
    name: 'Painters',
    description: 'Professional painting and finishing services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for painting projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate painting estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Job Tracking',
        description: 'Monitor painting projects and schedules',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'carpet-cleaners',
    name: 'Carpet Cleaners',
    description: 'Professional carpet and upholstery cleaning',
    benefits: [
      {
        title: 'Service Templates',
        description: 'Templates for cleaning services',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate cleaning estimates instantly',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track cleaning appointments',
        icon: AccessTime,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'voice-actors',
    name: 'Voice Actors',
    description: 'Professional voice-over and narration services',
    benefits: [
      {
        title: 'Project Templates',
        description: 'Templates for voice-over projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate voice work estimates',
        icon: Bolt,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor recording sessions and deadlines',
        icon: PieChart,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'translators',
    name: 'Translators',
    description: 'Professional translation and interpretation services',
    benefits: [
      {
        title: 'Translation Templates',
        description: 'Templates for translation projects',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Project Tracking',
        description: 'Monitor translation progress',
        icon: PieChart,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate translation estimates instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  },
  {
    slug: 'tour-guides',
    name: 'Tour Guides',
    description: 'Professional tour and travel guide services',
    benefits: [
      {
        title: 'Tour Templates',
        description: 'Templates for tour itineraries',
        icon: AutoGraph,
        color: 'bg-blue-100 text-blue-600'
      },
      {
        title: 'Schedule Management',
        description: 'Track tour bookings and schedules',
        icon: AccessTime,
        color: 'bg-amber-100 text-blue-600'
      },
      {
        title: 'Quick Quotes',
        description: 'Generate tour pricing instantly',
        icon: Bolt,
        color: 'bg-emerald-100 text-blue-600'
      }
    ]
  }
  ,{
    slug: 'cleaners',
    name: 'Cleaners',
    description: 'Professional cleaning services with customized document templates.',
    benefits: [
      {
        title: 'Professional Documentation',
        description: 'Create professional invoices, contracts, and quotes for your cleaning business.',
        icon: Receipt,
        color: '#4CAF50'
      },
      {
        title: 'Time Management',
        description: 'Track cleaning schedules and manage client appointments efficiently.',
        icon: AccessTime,
        color: '#2196F3'
      },
      {
        title: 'Business Growth',
        description: 'Scale your cleaning business with organized documentation.',
        icon: AutoGraph,
        color: '#9C27B0'
      }
    ]
  },
  {
    slug: 'movers',
    name: 'Movers',
    description: 'Professional moving services with customized document templates.',
    benefits: [
      {
        title: 'Professional Documentation',
        description: 'Create professional invoices, contracts, and quotes for your moving business.',
        icon: Receipt,
        color: '#4CAF50'
      },
      {
        title: 'Efficient Operations',
        description: 'Manage moving schedules and track deliveries effectively.',
        icon: Bolt,
        color: '#FF9800'
      },
      {
        title: 'Business Analytics',
        description: 'Track your moving business performance with detailed reports.',
        icon: PieChart,
        color: '#E91E63'
      }
    ]
  }
];

export default industries;