import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import { ReceiptLong, InfoOutlined } from '@mui/icons-material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import CalculatorMeta from '../../components/DynamicSEO/CalculatorMeta';

interface TaxBreakdown {
  name: string;
  amount: number;
}

interface DeductionItem {
  name: string;
  amount: number;
}

interface CalculationResult {
  grossIncome: number;
  totalDeductions: number;
  netIncome: number;
  selfEmploymentTax: number;
  incomeTax: number;
  totalTax: number;
  effectiveTaxRate: number;
  quarterlyPayments: number;
  taxBreakdown: TaxBreakdown[];
  deductionBreakdown: DeductionItem[];
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const deductionCategories = [
  { value: 'home_office', label: 'Home Office', tooltip: 'Portion of rent/mortgage, utilities, and maintenance for your workspace' },
  { value: 'equipment', label: 'Equipment & Supplies', tooltip: 'Computers, software, office supplies, and work-related tools' },
  { value: 'insurance', label: 'Insurance Premiums', tooltip: 'Health insurance, liability insurance, and other business-related coverage' },
  { value: 'travel', label: 'Travel & Transportation', tooltip: 'Business travel, mileage, and local transportation costs' },
  { value: 'marketing', label: 'Marketing & Advertising', tooltip: 'Website costs, advertising, and promotional materials' },
  { value: 'professional', label: 'Professional Services', tooltip: 'Legal fees, accounting services, and consulting costs' },
  { value: 'education', label: 'Education & Training', tooltip: 'Courses, workshops, and professional development' },
  { value: 'other', label: 'Other Expenses', tooltip: 'Miscellaneous business-related expenses' },
];

const taxBrackets2024 = [
  { min: 0, max: 11600, rate: 0.10 },
  { min: 11600, max: 47150, rate: 0.12 },
  { min: 47150, max: 100525, rate: 0.22 },
  { min: 100525, max: 191950, rate: 0.24 },
  { min: 191950, max: 243725, rate: 0.32 },
  { min: 243725, max: 609350, rate: 0.35 },
  { min: 609350, max: Infinity, rate: 0.37 },
];

export default function SelfEmployedTaxCalculator() {
  const [grossIncome, setGrossIncome] = useState('');
  const [deductions, setDeductions] = useState<{ [key: string]: string }>({});
  const [filingStatus, setFilingStatus] = useState('single');
  const [result, setResult] = useState<CalculationResult>({
    grossIncome: 0,
    totalDeductions: 0,
    netIncome: 0,
    selfEmploymentTax: 0,
    incomeTax: 0,
    totalTax: 0,
    effectiveTaxRate: 0,
    quarterlyPayments: 0,
    taxBreakdown: [],
    deductionBreakdown: [],
  });

  const calculateTax = () => {
    const income = parseFloat(grossIncome.replace(/,/g, '')) || 0;
    const totalDeductions = Object.values(deductions).reduce(
      (sum, value) => sum + (parseFloat(value.replace(/,/g, '')) || 0),
      0
    );

    const netIncome = Math.max(0, income - totalDeductions);
    const selfEmploymentTaxableIncome = netIncome * 0.9235; // 92.35% of net earnings
    const selfEmploymentTax = selfEmploymentTaxableIncome * 0.153; // 15.3% SE tax

    // Calculate income tax
    const taxableIncome = netIncome - (selfEmploymentTax * 0.5); // Deduct half of SE tax
    let incomeTax = 0;
    let remainingIncome = taxableIncome;

    for (let i = 0; i < taxBrackets2024.length; i++) {
      const bracket = taxBrackets2024[i];
      const prevBracket = i > 0 ? taxBrackets2024[i - 1] : { min: 0, max: 0 };
      const bracketIncome = Math.min(
        remainingIncome,
        bracket.max - prevBracket.max
      );

      if (bracketIncome > 0) {
        incomeTax += bracketIncome * bracket.rate;
        remainingIncome -= bracketIncome;
      }

      if (remainingIncome <= 0) break;
    }

    const totalTax = selfEmploymentTax + incomeTax;
    const effectiveTaxRate = (totalTax / netIncome) * 100;
    const quarterlyPayments = totalTax / 4;

    const deductionBreakdown = Object.entries(deductions)
      .map(([key, value]) => ({
        name: deductionCategories.find(cat => cat.value === key)?.label || key,
        amount: parseFloat(value.replace(/,/g, '')) || 0,
      }))
      .filter(item => item.amount > 0);

    const taxBreakdown = [
      { name: 'Self-Employment Tax', amount: selfEmploymentTax },
      { name: 'Income Tax', amount: incomeTax },
    ];

    setResult({
      grossIncome: income,
      totalDeductions,
      netIncome,
      selfEmploymentTax,
      incomeTax,
      totalTax,
      effectiveTaxRate,
      quarterlyPayments,
      taxBreakdown,
      deductionBreakdown,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercent = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value / 100);
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Gross Annual Income"
          value={grossIncome}
          onChange={(e) => setGrossIncome(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Filing Status</InputLabel>
          <Select
            value={filingStatus}
            label="Filing Status"
            onChange={(e) => setFilingStatus(e.target.value)}
          >
            <MenuItem value="single">Single</MenuItem>
            <MenuItem value="married_joint">Married Filing Jointly</MenuItem>
            <MenuItem value="married_separate">Married Filing Separately</MenuItem>
            <MenuItem value="head">Head of Household</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Business Deductions
        </Typography>
        <Grid container spacing={2}>
          {deductionCategories.map((category) => (
            <Grid item xs={12} sm={6} key={category.value}>
              <TextField
                fullWidth
                label={category.label}
                value={deductions[category.value] || ''}
                onChange={(e) =>
                  setDeductions({
                    ...deductions,
                    [category.value]: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={category.tooltip}>
                        <IconButton size="small">
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={calculateTax}
          size="large"
        >
          Calculate Taxes
        </Button>
      </Grid>
    </Grid>
  );

  const resultSection = result.totalTax > 0 && (
    <>
      <Typography variant="h5" gutterBottom>
        Tax Summary
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Total Tax Due
                  </Typography>
                  <Typography variant="h4" color="error" gutterBottom>
                    {formatCurrency(result.totalTax)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Effective Rate: {formatPercent(result.effectiveTaxRate)}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%', bgcolor: 'primary.light' }}>
                  <Typography variant="subtitle2" color="white">
                    Quarterly Payments
                  </Typography>
                  <Typography variant="h4" color="white" gutterBottom>
                    {formatCurrency(result.quarterlyPayments)}
                  </Typography>
                  <Typography variant="body2" color="white">
                    Due every quarter
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Paper sx={{ p: 2, textAlign: 'center', height: '100%' }}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Net Income
                  </Typography>
                  <Typography variant="h4" color="success.main" gutterBottom>
                    {formatCurrency(result.netIncome)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    After deductions
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Tax Breakdown
          </Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={result.taxBreakdown}
                  dataKey="amount"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={({ name, value }) => `${name}: ${formatCurrency(value)}`}
                >
                  {result.taxBreakdown.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip
                  formatter={(value: number) => formatCurrency(value)}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Deduction Summary
          </Typography>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <BarChart data={result.deductionBreakdown}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <RechartsTooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
                <Bar dataKey="amount" fill="#82ca9d" name="Amount" />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Calculation Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Gross Income
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(result.grossIncome)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Total Deductions
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(result.totalDeductions)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Self-Employment Tax
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(result.selfEmploymentTax)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" color="text.secondary">
                  Income Tax
                </Typography>
                <Typography variant="body1">
                  {formatCurrency(result.incomeTax)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        This calculator helps self-employed individuals estimate their tax obligations:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Self-Employment Tax (SE):</strong> 15.3% tax covering Social Security (12.4%) and Medicare (2.9%)
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Income Tax:</strong> Federal tax based on 2024 tax brackets
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Deductions:</strong> Business expenses that reduce your taxable income
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Quarterly Payments:</strong> Estimated tax payments due April 15, June 15, September 15, and January 15
          </Typography>
        </li>
      </ul>
      <Typography variant="body2" sx={{ mt: 2 }}>
        Important Notes:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            This calculator provides estimates only. Consult a tax professional for accurate tax planning.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            State and local taxes are not included in these calculations.
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            Some deductions may have limitations or require specific documentation.
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Self-Employed Tax Calculator"
        description="Estimate your self-employment tax, income tax, and quarterly payments. Track deductions and understand your tax obligations as a freelancer or independent contractor."
        calculatorType="self-employed-tax"
        features={[
          'Self-employment tax calculation',
          'Income tax estimation',
          'Business deduction tracking',
          'Quarterly payment planning',
          'Tax breakdown visualization',
          'Deduction summary charts',
        ]}
        keywords={[
          'self employed tax calculator',
          'freelance tax estimation',
          'quarterly tax payments',
          'business deductions',
          'self employment tax',
          'tax planning',
          '1099 tax calculator',
          'independent contractor taxes',
        ]}
      />
      <CalculatorLayout
        title="Self-Employed Tax Calculator"
        description="Estimate your tax obligations and plan quarterly payments"
        icon={ReceiptLong}
        inputSection={inputSection}
        resultSection={resultSection}
        helpSection={helpSection}
      />
    </>
  );
}
