import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Divider,
  useTheme,
  Alert
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { CalculateOutlined } from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';

interface ChartData {
  month: number;
  principalPaid: number;
  interestPaid: number;
  remainingBalance: number;
}

const MortgageCalculator: React.FC = () => {
  const [loanAmount, setLoanAmount] = useState<number>(300000);
  const [interestRate, setInterestRate] = useState<number>(3.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  const [downPayment, setDownPayment] = useState<number>(60000);
  const [monthlyPayment, setMonthlyPayment] = useState<number>(0);
  const [totalPayment, setTotalPayment] = useState<number>(0);
  const [totalInterest, setTotalInterest] = useState<number>(0);
  const [chartData, setChartData] = useState<ChartData[]>([]);

  const calculateMortgage = () => {
    const principal = loanAmount - downPayment;
    const monthlyRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;

    // Calculate monthly payment using the mortgage payment formula
    const monthly = principal * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
                   (Math.pow(1 + monthlyRate, numberOfPayments) - 1);

    setMonthlyPayment(monthly);
    setTotalPayment(monthly * numberOfPayments);
    setTotalInterest((monthly * numberOfPayments) - principal);

    // Generate amortization schedule for the chart
    let balance = principal;
    const newChartData: ChartData[] = [];

    for (let month = 1; month <= numberOfPayments; month += 12) {
      const interestPayment = balance * monthlyRate;
      const principalPayment = monthly - interestPayment;
      balance = balance - principalPayment;

      newChartData.push({
        month: month,
        principalPaid: principalPayment,
        interestPaid: interestPayment,
        remainingBalance: balance
      });
    }

    setChartData(newChartData);
  };

  useEffect(() => {
    calculateMortgage();
  }, [loanAmount, interestRate, loanTerm, downPayment]);

  return (
    <>
      <Helmet>
        <title>Mortgage Calculator | Lipana - Calculate Monthly Payments & Amortization</title>
        <meta name="description" content="Free mortgage calculator to estimate your monthly mortgage payment. See payment breakdown, amortization schedule, and total interest with our easy-to-use calculator." />
        <meta name="keywords" content="mortgage calculator, home loan calculator, mortgage payment calculator, amortization schedule, house payment calculator" />
        <meta property="og:title" content="Mortgage Calculator | Lipana" />
        <meta property="og:description" content="Calculate your monthly mortgage payments, view amortization schedules, and plan your home loan with our free mortgage calculator." />
        <link rel="canonical" href="https://lipana.app/calculators/mortgage" />
      </Helmet>
      <CalculatorLayout
        title="Mortgage Calculator"
        description="Plan your home loan with our comprehensive mortgage calculator"
        icon={CalculateOutlined}
        inputSection={
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                  Loan Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Loan Amount"
                      type="number"
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(Number(e.target.value))}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoney />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Down Payment"
                      type="number"
                      value={downPayment}
                      onChange={(e) => setDownPayment(Number(e.target.value))}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoney />
                          </InputAdornment>
                        ),
                      }}
                      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Interest Rate"
                      type="number"
                      value={interestRate}
                      onChange={(e) => setInterestRate(Number(e.target.value))}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Loan Term (Years)"
                      type="number"
                      value={loanTerm}
                      onChange={(e) => setLoanTerm(Number(e.target.value))}
                      sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        }
        resultSection={
          <>
            <Grid item xs={12} md={6}>
              <Card elevation={3} sx={{ borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                    Payment Summary
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" color="text.secondary">Monthly Payment:</Typography>
                        <Typography variant="h6" color="primary.main">${monthlyPayment.toFixed(2)}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" color="text.secondary">Total Payment:</Typography>
                        <Typography variant="h6">${totalPayment.toFixed(2)}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1" color="text.secondary">Total Interest:</Typography>
                        <Typography variant="h6" color="error.main">${totalInterest.toFixed(2)}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                  Amortization Schedule
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ width: '100%', height: 400 }}>
                  <ResponsiveContainer>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" label={{ value: 'Month', position: 'bottom' }} />
                      <YAxis label={{ value: 'Amount ($)', angle: -90, position: 'left' }} />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="remainingBalance"
                        stroke="#2196f3"
                        name="Remaining Balance"
                      />
                      <Line
                        type="monotone"
                        dataKey="principalPaid"
                        stroke="#4caf50"
                        name="Principal Paid"
                      />
                      <Line
                        type="monotone"
                        dataKey="interestPaid"
                        stroke="#f44336"
                        name="Interest Paid"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Card>
            </Grid>
          </>
        }
      />
    </>
  );
};

export default MortgageCalculator;
