import React from 'react';
import { Helmet } from 'react-helmet-async';

interface CalculatorMetaProps {
  title: string;
  description: string;
  calculatorType: string;
  features: string[];
  keywords: string[];
}

export default function CalculatorMeta({
  title,
  description,
  calculatorType,
  features,
  keywords,
}: CalculatorMetaProps) {
  const baseUrl = 'https://lipana.app';
  const canonicalUrl = `${baseUrl}/calculators/${calculatorType}`;

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    name: title,
    description: description,
    applicationCategory: 'FinancialCalculator',
    operatingSystem: 'Web',
    url: canonicalUrl,
    offers: {
      '@type': 'Offer',
      price: '0',
      priceCurrency: 'USD',
    },
    featureList: features.join(', '),
    keywords: keywords.join(', '),
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title} | Lipana Financial Tools</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      <meta name="keywords" content={keywords.join(', ')} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={`${title} | Lipana Financial Tools`} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:site_name" content="Lipana" />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}
