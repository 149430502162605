import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  Divider,
  Chip,
  useTheme,
  Alert
} from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Timeline, TimelineOutlined, Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Task {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  progress: number;
}

const GanttChartGenerator: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState<Task>({
    id: '',
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    progress: 0,
  });

  const addTask = () => {
    if (newTask.name && newTask.startDate && newTask.endDate) {
      setTasks([...tasks, { ...newTask, id: Date.now().toString() }]);
      setNewTask({
        id: '',
        name: '',
        startDate: new Date(),
        endDate: new Date(),
        progress: 0,
      });
    }
  };

  const removeTask = (taskId: string) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  const generateChartData = () => {
    const data = {
      labels: tasks.map(task => task.name),
      datasets: [
        {
          label: 'Task Duration',
          data: tasks.map(task => {
            const duration = Math.ceil(
              (task.endDate.getTime() - task.startDate.getTime()) / (1000 * 60 * 60 * 24)
            );
            return duration;
          }),
          backgroundColor: tasks.map(() => 'rgba(54, 162, 235, 0.8)'),
          borderColor: tasks.map(() => 'rgba(54, 162, 235, 1)'),
          borderWidth: 1,
        },
        {
          label: 'Progress',
          data: tasks.map(task => task.progress),
          backgroundColor: tasks.map(() => 'rgba(255, 99, 132, 0.8)'),
          borderColor: tasks.map(() => 'rgba(255, 99, 132, 1)'),
          borderWidth: 1,
        },
      ],
    };
    return data;
  };

  const chartOptions = {
    indexAxis: 'y' as const,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Project Timeline',
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Duration (days)',
        },
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Gantt Chart Generator | Lipana - Project Timeline Visualization</title>
        <meta name="description" content="Create professional Gantt charts for your projects. Visualize timelines, track progress, and manage tasks with our easy-to-use Gantt chart generator." />
        <meta name="keywords" content="gantt chart, project timeline, project management, task scheduler, timeline generator" />
        <meta property="og:title" content="Gantt Chart Generator | Lipana" />
        <meta property="og:description" content="Create and visualize project timelines with our interactive Gantt chart generator. Perfect for project managers and teams." />
        <link rel="canonical" href="https://lipana.app/calculators/gantt" />
      </Helmet>
      <CalculatorLayout
        title="Gantt Chart Generator"
        description="Create professional project timelines and track progress"
        icon={TimelineOutlined}
        inputSection={
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card elevation={3} sx={{ borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                    Add New Task
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Task Name"
                        value={newTask.name}
                        onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Start Date"
                          value={newTask.startDate}
                          onChange={(date) => setNewTask({ ...newTask, startDate: date || new Date() })}
                          sx={{ width: '100%', '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="End Date"
                          value={newTask.endDate}
                          onChange={(date) => setNewTask({ ...newTask, endDate: date || new Date() })}
                          sx={{ width: '100%', '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Progress (%)"
                        type="number"
                        value={newTask.progress}
                        onChange={(e) => setNewTask({ ...newTask, progress: Math.min(100, Math.max(0, Number(e.target.value))) })}
                        inputProps={{ min: 0, max: 100 }}
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addTask}
                        fullWidth
                        sx={{ borderRadius: 2, py: 1.5 }}
                      >
                        Add Task
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card elevation={3} sx={{ borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                    Task List
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <List>
                    {tasks.map((task) => (
                      <ListItem
                        key={task.id}
                        sx={{
                          mb: 1,
                          bgcolor: 'background.paper',
                          borderRadius: 2,
                          border: '1px solid',
                          borderColor: 'divider',
                        }}
                      >
                        <ListItemText
                          primary={task.name}
                          secondary={
                            <Box sx={{ mt: 1 }}>
                              <Chip
                                label={`${task.startDate.toLocaleDateString()} - ${task.endDate.toLocaleDateString()}`}
                                size="small"
                                sx={{ mr: 1 }}
                              />
                              <Chip
                                label={`Progress: ${task.progress}%`}
                                color="primary"
                                size="small"
                              />
                            </Box>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => removeTask(task.id)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                    {tasks.length === 0 && (
                      <Alert severity="info" sx={{ mt: 2 }}>
                        No tasks added yet. Start by adding your first task above.
                      </Alert>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>

            {tasks.length > 0 && (
              <Grid item xs={12}>
                <Card elevation={3} sx={{ p: 3, borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                    Project Timeline
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ width: '100%', height: 400 }}>
                    <Bar options={chartOptions} data={generateChartData()} />
                  </Box>
                </Card>
              </Grid>
            )}
          </Grid>
        }
      />
    </>
  );
};

export default GanttChartGenerator;
