import React from 'react';
import { Helmet } from 'react-helmet-async';

interface DynamicMetaProps {
  title: string;
  description: string;
  canonicalUrl: string;
  industry: string;
  documentType: string;
  imageUrl?: string;
}

export default function DynamicMeta({
  title,
  description,
  canonicalUrl,
  industry,
  documentType,
  imageUrl
}: DynamicMetaProps) {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: title,
    description: description,
    url: canonicalUrl,
    mainEntity: {
      '@type': 'SoftwareApplication',
      name: `Lipana ${documentType} Generator for ${industry}`,
      applicationCategory: 'BusinessApplication',
      operatingSystem: 'Web',
      offers: {
        '@type': 'Offer',
        price: '0',
        priceCurrency: 'USD'
      }
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}

      {/* Industry and Document Type Specific Meta Tags */}
      <meta name="keywords" content={`${industry} ${documentType}, ${industry} template, professional ${documentType}, ${documentType} generator, business documents`} />
      <meta name="robots" content="index, follow" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
}
