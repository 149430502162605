import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { AccountBalance } from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import CalculatorMeta from '../../components/DynamicSEO/CalculatorMeta';

interface AmortizationRow {
  paymentNumber: number;
  payment: number;
  principal: number;
  interest: number;
  remainingBalance: number;
}

export default function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [result, setResult] = useState<{
    monthlyPayment: number;
    totalPayment: number;
    totalInterest: number;
    amortizationSchedule: AmortizationRow[];
    chartData: any[];
  }>({
    monthlyPayment: 0,
    totalPayment: 0,
    totalInterest: 0,
    amortizationSchedule: [],
    chartData: [],
  });

  const calculateLoan = () => {
    const principal = parseFloat(loanAmount.replace(/,/g, ''));
    const rate = parseFloat(interestRate) / 100 / 12;
    const terms = parseFloat(loanTerm) * 12;

    if (isNaN(principal) || isNaN(rate) || isNaN(terms)) return;

    const monthlyPayment =
      (principal * rate * Math.pow(1 + rate, terms)) /
      (Math.pow(1 + rate, terms) - 1);
    const totalPayment = monthlyPayment * terms;
    const totalInterest = totalPayment - principal;

    // Calculate amortization schedule
    let balance = principal;
    const schedule: AmortizationRow[] = [];
    const chartData: any[] = [];
    let totalPrincipalPaid = 0;
    let totalInterestPaid = 0;

    for (let i = 1; i <= terms; i++) {
      const interestPayment = balance * rate;
      const principalPayment = monthlyPayment - interestPayment;
      balance -= principalPayment;

      totalPrincipalPaid += principalPayment;
      totalInterestPaid += interestPayment;

      schedule.push({
        paymentNumber: i,
        payment: monthlyPayment,
        principal: principalPayment,
        interest: interestPayment,
        remainingBalance: balance,
      });

      if (i % 12 === 0 || i === 1 || i === terms) {
        chartData.push({
          month: i,
          remainingBalance: balance,
          totalPrincipalPaid,
          totalInterestPaid,
        });
      }
    }

    setResult({
      monthlyPayment,
      totalPayment,
      totalInterest,
      amortizationSchedule: schedule,
      chartData,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Loan Amount"
          value={loanAmount}
          onChange={(e) => setLoanAmount(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Annual Interest Rate"
          value={interestRate}
          onChange={(e) => setInterestRate(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Loan Term (Years)"
          value={loanTerm}
          onChange={(e) => setLoanTerm(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">years</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={calculateLoan}
          size="large"
        >
          Calculate Loan Payments
        </Button>
      </Grid>
    </Grid>
  );

  const resultSection = result.monthlyPayment > 0 && (
    <>
      <Typography variant="h5" gutterBottom>
        Loan Analysis Results
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">Monthly Payment:</Typography>
          <Typography variant="h6" color="primary">
            {formatCurrency(result.monthlyPayment)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">Total Payment:</Typography>
          <Typography variant="h6" color="primary">
            {formatCurrency(result.totalPayment)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1">Total Interest:</Typography>
          <Typography variant="h6" color="primary">
            {formatCurrency(result.totalInterest)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment Breakdown Over Time
          </Typography>
          <Box sx={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <AreaChart
                data={result.chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="month"
                  label={{
                    value: 'Month',
                    position: 'insideBottom',
                    offset: -5,
                  }}
                />
                <YAxis
                  label={{
                    value: 'Amount ($)',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="remainingBalance"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                  name="Remaining Balance"
                />
                <Area
                  type="monotone"
                  dataKey="totalPrincipalPaid"
                  stackId="2"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  name="Principal Paid"
                />
                <Area
                  type="monotone"
                  dataKey="totalInterestPaid"
                  stackId="2"
                  stroke="#ffc658"
                  fill="#ffc658"
                  name="Interest Paid"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Amortization Schedule
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Payment #</TableCell>
                  <TableCell align="right">Payment</TableCell>
                  <TableCell align="right">Principal</TableCell>
                  <TableCell align="right">Interest</TableCell>
                  <TableCell align="right">Remaining Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result.amortizationSchedule.map((row) => (
                  <TableRow key={row.paymentNumber}>
                    <TableCell>{row.paymentNumber}</TableCell>
                    <TableCell align="right">
                      {formatCurrency(row.payment)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(row.principal)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(row.interest)}
                    </TableCell>
                    <TableCell align="right">
                      {formatCurrency(row.remainingBalance)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        This loan calculator helps you understand the full cost of borrowing and plan your repayment schedule:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Monthly Payment:</strong> Your regular payment amount including principal and interest
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Total Payment:</strong> The total amount you'll pay over the life of the loan
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Total Interest:</strong> The total cost of borrowing
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Amortization Schedule:</strong> A detailed breakdown of each payment
          </Typography>
        </li>
      </ul>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Loan Payment Calculator"
        description="Calculate your monthly loan payments, total interest, and view a detailed amortization schedule. Plan your loan repayment with our interactive calculator and visualization tools."
        calculatorType="loan"
        features={[
          'Monthly payment calculation',
          'Total interest and payment analysis',
          'Interactive payment breakdown chart',
          'Detailed amortization schedule',
          'Visual loan balance tracking',
        ]}
        keywords={[
          'loan calculator',
          'mortgage calculator',
          'amortization schedule',
          'loan payment calculator',
          'interest calculator',
          'debt calculator',
          'loan amortization',
          'financial planning',
        ]}
      />
      <CalculatorLayout
        title="Loan Payment Calculator"
        description="Calculate your monthly loan payments and view a detailed amortization schedule"
        icon={AccountBalance}
        inputSection={inputSection}
        resultSection={resultSection}
        helpSection={helpSection}
      />
    </>
  );
}
