import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  InputAdornment,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
import { Home } from '@mui/icons-material';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import CalculatorLayout from '../../components/calculators/CalculatorLayout';
import CalculatorMeta from '../../components/DynamicSEO/CalculatorMeta';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

interface ChartData {
  name: string;
  value: number;
}

interface CalculationResult {
  totalCommission: number;
  listingAgent: number;
  buyerAgent: number;
  listingBroker: number;
  buyerBroker: number;
  chartData: ChartData[];
}

export default function RealEstateCommissionCalculator() {
  const [salePrice, setSalePrice] = useState('');
  const [commissionRate, setCommissionRate] = useState(6);
  const [splitType, setSplitType] = useState('50-50');
  const [brokerSplit, setBrokerSplit] = useState(30);
  const [result, setResult] = useState<CalculationResult>({
    totalCommission: 0,
    listingAgent: 0,
    buyerAgent: 0,
    listingBroker: 0,
    buyerBroker: 0,
    chartData: [],
  });

  const calculateCommission = () => {
    const price = parseFloat(salePrice.replace(/,/g, ''));
    if (isNaN(price)) return;

    const totalCommission = (price * commissionRate) / 100;
    let listingShare = 0;
    let buyerShare = 0;

    // Calculate agent splits
    switch (splitType) {
      case '50-50':
        listingShare = totalCommission / 2;
        buyerShare = totalCommission / 2;
        break;
      case '60-40':
        listingShare = (totalCommission * 0.6);
        buyerShare = (totalCommission * 0.4);
        break;
      case '70-30':
        listingShare = (totalCommission * 0.7);
        buyerShare = (totalCommission * 0.3);
        break;
    }

    // Calculate broker splits
    const listingBroker = (listingShare * brokerSplit) / 100;
    const buyerBroker = (buyerShare * brokerSplit) / 100;
    const listingAgent = listingShare - listingBroker;
    const buyerAgent = buyerShare - buyerBroker;

    const chartData: ChartData[] = [
      { name: 'Listing Agent', value: listingAgent },
      { name: 'Buyer Agent', value: buyerAgent },
      { name: 'Listing Broker', value: listingBroker },
      { name: 'Buyer Broker', value: buyerBroker },
    ];

    setResult({
      totalCommission,
      listingAgent,
      buyerAgent,
      listingBroker,
      buyerBroker,
      chartData,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const inputSection = (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Sale Price"
          value={salePrice}
          onChange={(e) => setSalePrice(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Commission Rate (%)</Typography>
        <Slider
          value={commissionRate}
          onChange={(_, value) => setCommissionRate(value as number)}
          step={0.5}
          marks
          min={1}
          max={10}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Commission Split</InputLabel>
          <Select
            value={splitType}
            label="Commission Split"
            onChange={(e) => setSplitType(e.target.value)}
          >
            <MenuItem value="50-50">50/50 Split</MenuItem>
            <MenuItem value="60-40">60/40 Split</MenuItem>
            <MenuItem value="70-30">70/30 Split</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom>Broker Split (%)</Typography>
        <Slider
          value={brokerSplit}
          onChange={(_, value) => setBrokerSplit(value as number)}
          step={5}
          marks
          min={0}
          max={50}
          valueLabelDisplay="auto"
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={calculateCommission}
          size="large"
        >
          Calculate Commission
        </Button>
      </Grid>
    </Grid>
  );

  const resultSection = result.totalCommission > 0 && (
    <>
      <Typography variant="h5" gutterBottom>
        Commission Breakdown
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="primary" gutterBottom>
            {formatCurrency(result.totalCommission)}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Total Commission
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={result.chartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label={(entry) => `${entry.name}: ${formatCurrency(entry.value)}`}
                >
                  {result.chartData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatCurrency(Number(value))} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Listing Side</Typography>
                <Typography>
                  Agent: {formatCurrency(result.listingAgent)}
                </Typography>
                <Typography>
                  Broker: {formatCurrency(result.listingBroker)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2">Buyer Side</Typography>
                <Typography>
                  Agent: {formatCurrency(result.buyerAgent)}
                </Typography>
                <Typography>
                  Broker: {formatCurrency(result.buyerBroker)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );

  const helpSection = (
    <>
      <Typography variant="body2" paragraph>
        This calculator helps real estate professionals calculate commission splits between all parties:
      </Typography>
      <ul className="list-disc pl-6 space-y-2">
        <li>
          <Typography variant="body2">
            <strong>Commission Rate:</strong> The total percentage charged on the sale price
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Commission Split:</strong> How the commission is divided between listing and buyer sides
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <strong>Broker Split:</strong> The percentage that goes to the broker from each agent's share
          </Typography>
        </li>
      </ul>
      <Typography variant="body2" sx={{ mt: 2 }}>
        The calculator provides a clear breakdown of how the commission will be distributed among all parties involved in the transaction.
      </Typography>
    </>
  );

  return (
    <>
      <CalculatorMeta
        title="Real Estate Commission Calculator"
        description="Calculate real estate commission splits between agents and brokers. Visualize how commissions are distributed with our interactive calculator."
        calculatorType="real-estate-commission"
        features={[
          'Commission rate calculation',
          'Flexible split ratios',
          'Broker split calculation',
          'Visual commission breakdown',
          'Detailed distribution analysis',
        ]}
        keywords={[
          'real estate commission',
          'commission calculator',
          'realtor fees',
          'broker split',
          'agent commission',
          'real estate fees',
          'commission split',
          'real estate calculator',
        ]}
      />
      <CalculatorLayout
        title="Real Estate Commission Calculator"
        description="Calculate and visualize commission splits between agents and brokers"
        icon={Home}
        inputSection={inputSection}
        resultSection={resultSection}
        helpSection={helpSection}
      />
    </>
  );
}
