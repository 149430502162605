import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

interface TimeEntry {
  date: string;
  startTime: string;
  endTime: string;
  breakDuration: number;
  description: string;
}

interface CalculatorMetaProps {
  title: string;
  description: string;
  calculatorType: string;
  features: string[];
  keywords: string[];
}

const CalculatorMeta: React.FC<CalculatorMetaProps> = ({ 
  title, 
  description, 
  calculatorType, 
  features, 
  keywords 
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />
    </>
  );
};

const TimeSheetCalculator = () => {
  const [hourlyRate, setHourlyRate] = useState('');
  const [entries, setEntries] = useState<TimeEntry[]>([
    {
      date: new Date().toISOString().split('T')[0],
      startTime: '',
      endTime: '',
      breakDuration: 0,
      description: '',
    },
  ]);
  const [result, setResult] = useState({
    totalHours: 0,
    totalEarnings: 0,
    entrySummary: [] as Array<{
      date: string;
      hours: number;
      earnings: number;
    }>,
  });

  const addEntry = () => {
    setEntries([
      ...entries,
      {
        date: new Date().toISOString().split('T')[0],
        startTime: '',
        endTime: '',
        breakDuration: 0,
        description: '',
      },
    ]);
  };

  const removeEntry = (index: number) => {
    setEntries(entries.filter((_, i) => i !== index));
  };

  const handleEntryChange = (
    index: number,
    field: keyof TimeEntry,
    value: string | number
  ) => {
    const newEntries = [...entries];
    newEntries[index] = {
      ...newEntries[index],
      [field]: value,
    };
    setEntries(newEntries);
  };

  const calculateHours = (entry: TimeEntry) => {
    if (!entry.startTime || !entry.endTime) return 0;

    const [startHours, startMinutes] = entry.startTime.split(':').map(Number);
    const [endHours, endMinutes] = entry.endTime.split(':').map(Number);

    let hours =
      (endHours + endMinutes / 60) - (startHours + startMinutes / 60);
    
    // Adjust for negative hours (crossing midnight)
    if (hours < 0) hours += 24;

    // Subtract break duration
    return Math.max(0, hours - entry.breakDuration / 60);
  };

  const calculateTimesheet = () => {
    const rate = parseFloat(hourlyRate);
    if (isNaN(rate)) return;

    const entrySummary = entries.map((entry) => {
      const hours = calculateHours(entry);
      return {
        date: entry.date,
        hours,
        earnings: hours * rate,
      };
    });

    const totalHours = entrySummary.reduce((sum, entry) => sum + entry.hours, 0);
    const totalEarnings = totalHours * rate;

    setResult({
      totalHours,
      totalEarnings,
      entrySummary,
    });
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };

  const formatHours = (hours: number) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h ${minutes}m`;
  };

  return (
    <>
      <CalculatorMeta
        title="Timesheet Calculator with Earnings"
        description="Free timesheet calculator to track work hours and calculate earnings. Perfect for freelancers, contractors, and hourly workers to manage time and income."
        calculatorType="timesheet"
        features={[
          'Track multiple time entries',
          'Calculate total hours worked',
          'Automatic earnings calculation',
          'Break time tracking',
          'Daily summaries',
          'Export timesheet data'
        ]}
        keywords={[
          'timesheet calculator',
          'work hours calculator',
          'earnings calculator',
          'time tracking tool',
          'payroll calculator',
          'hourly wage calculator',
          'work time tracker',
          'break time calculator',
          'overtime calculator',
          'employee timesheet'
        ]}
      />
      <Box sx={{ 
        maxWidth: '1200px', 
        margin: '0 auto',
        padding: '2rem',
        '& .MuiPaper-root': {
          borderRadius: '12px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)'
        }
      }}>
        <Typography 
          variant="h4" 
          component="h1" 
          gutterBottom
          sx={{
            fontWeight: 700,
            color: '#1a237e',
            textAlign: 'center',
            mb: 4
          }}
        >
          Timesheet Calculator
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                Set Hourly Rate
              </Typography>
              <TextField
                fullWidth
                label="Hourly Rate"
                value={hourlyRate}
                onChange={(e) => setHourlyRate(e.target.value)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                sx={{ mb: 2 }}
              />
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ color: '#1a237e' }}>
                  Time Entries
                </Typography>
                <Button
                  startIcon={<AddIcon />}
                  onClick={addEntry}
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(45deg, #1a237e 30%, #3949ab 90%)',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #0d1642 30%, #2c3a8c 90%)',
                    }
                  }}
                >
                  Add Entry
                </Button>
              </Box>
              
              {entries.map((entry, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        type="date"
                        value={entry.date}
                        onChange={(e) =>
                          handleEntryChange(index, 'date', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        type="time"
                        label="Start Time"
                        value={entry.startTime}
                        onChange={(e) =>
                          handleEntryChange(index, 'startTime', e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        type="time"
                        label="End Time"
                        value={entry.endTime}
                        onChange={(e) =>
                          handleEntryChange(index, 'endTime', e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Break (mins)"
                        value={entry.breakDuration}
                        onChange={(e) =>
                          handleEntryChange(index, 'breakDuration', Number(e.target.value))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        fullWidth
                        label="Description"
                        value={entry.description}
                        onChange={(e) =>
                          handleEntryChange(index, 'description', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                      <IconButton
                        color="error"
                        onClick={() => removeEntry(index)}
                        disabled={entries.length === 1}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={calculateTimesheet}
              size="large"
              sx={{ mt: 3 }}
            >
              Calculate Time Sheet
            </Button>
          </Grid>

          {result.totalHours > 0 && (
            <Grid item xs={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#1a237e', mb: 3 }}>
                  Summary
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Total Hours
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatHours(result.totalHours)}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper sx={{ 
                      p: 2, 
                      textAlign: 'center',
                      background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)'
                    }}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Total Earnings
                      </Typography>
                      <Typography variant="h5" sx={{ color: '#1a237e', fontWeight: 'bold' }}>
                        {formatCurrency(result.totalEarnings)}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default TimeSheetCalculator;
