import React from 'react';
import { Link } from 'react-router-dom';

export default function MpesaApiDocs() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-12">
      <div className="prose prose-slate max-w-none">
        <h1 className="text-3xl sm:text-4xl font-bold mb-10">M-PESA API Documentation</h1>

        {/* Introduction */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Introduction</h2>
          <div className="text-slate-600 mb-4">
            <p>
              The Daraja API ("Daraja" means "bridge" in Swahili) connects your application to M-PESA, Kenya’s leading mobile money platform. With Daraja, you can:
            </p>
            <ul className="list-disc list-inside ml-4">
              <li>Accept payments from customers (C2B).</li>
              <li>Send money to customers or businesses (B2C, B2B).</li>
              <li>Check account balances and transaction statuses.</li>
              <li>Use STK Push for Lipa Na M-PESA Online payments.</li>
            </ul>
            <p>
              This documentation is for developers with basic knowledge of HTTP requests and JSON.
            </p>
          </div>
        </div>

        {/* Getting Started */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Getting Started</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">1. Create a Developer Account</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li>Visit <a href="https://developer.safaricom.co.ke" className="text-blue-600 hover:underline">developer.safaricom.co.ke</a>.</li>
                <li>Sign up with your email and create a password.</li>
                <li>Verify your account via the email link.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">2. Set Up Your App</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li>Log in to the portal.</li>
                <li>Click "My Apps" {'>'} "Add New App".</li>
                <li>Name your app (e.g., "MyStorePayments").</li>
                <li>Select the APIs you need (e.g., Lipa Na M-PESA Sandbox for testing).</li>
                <li>Save to generate your <strong>Consumer Key</strong> and <strong>Consumer Secret</strong>.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">3. Get Test Credentials</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li>In your app’s dashboard, click the "Test Credentials" icon.</li>
                <li>Note down your Consumer Key, Consumer Secret, and Sandbox Shortcode (e.g., 600998).</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">4. Go Live</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li>Test your integration thoroughly in the sandbox.</li>
                <li>Click "Go Live" in the portal.</li>
                <li>Provide your production shortcode (Paybill/Till Number) and credentials from the M-PESA G2 portal.</li>
                <li>Await Safaricom’s approval for live transactions.</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Core Concepts */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Core Concepts</h2>
          <div className="space-y-4">
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">Authentication</h3>
              <p className="text-slate-600">
                All API calls require an <strong>Access Token</strong>, which expires every hour (3600 seconds). Generate this token using your Consumer Key and Secret.
              </p>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">Base URLs</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li><strong>Sandbox (Testing):</strong> https://sandbox.safaricom.co.ke</li>
                <li><strong>Production (Live):</strong> https://api.safaricom.co.ke</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">Request Format</h3>
              <ul className="list-disc list-inside text-slate-600 ml-4">
                <li><strong>Method:</strong> Mostly POST</li>
                <li><strong>Content-Type:</strong> application/json</li>
                <li><strong>Authorization:</strong> Bearer &lt;Access Token&gt;</li>
              </ul>
            </div>
          </div>
        </div>

        {/* API Endpoints */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">API Endpoints</h2>

          {/* Generate Access Token */}
          <div className="mb-6">
            <h3 className="text-lg sm:text-xl font-medium mb-3">1. Generate Access Token</h3>
            <p className="text-slate-600 mb-2">Obtain a token to authenticate API requests.</p>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  GET /oauth/v1/generate?grant_type=client_credentials
                </code>
              </pre>
            </div>
            <p className="text-slate-600 mb-2"><strong>Authentication:</strong> Basic Auth (Consumer Key:Consumer Secret, Base64 encoded)</p>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  curl -X GET "https://sandbox.safaricom.co.ke/oauth/v1/generate?grant_type=client_credentials" \
                  -H "Authorization: Basic &lt;Base64(ConsumerKey:ConsumerSecret)&gt;"
                </code>
              </pre>
            </div>
            <p className="text-slate-600 mb-2"><strong>Example Response:</strong></p>
            <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  {`{
  "access_token": "your_access_token_here",
  "expires_in": "3600"
}`}
                </code>
              </pre>
            </div>
          </div>

          {/* STK Push */}
          <div className="mb-6">
            <h3 className="text-lg sm:text-xl font-medium mb-3">2. Lipa Na M-PESA Online (STK Push)</h3>
            <p className="text-slate-600 mb-2">Prompt a customer to pay by entering their M-PESA PIN.</p>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  POST /mpesa/stkpush/v1/processrequest
                </code>
              </pre>
            </div>
            <h4 className="text-base sm:text-lg font-medium mb-2">Parameters:</h4>
            <dl className="space-y-2">
              <div>
                <dt className="font-medium">BusinessShortCode</dt>
                <dd className="text-slate-600">Your Paybill/Till Number (e.g., "174379")</dd>
              </div>
              <div>
                <dt className="font-medium">Password</dt>
                <dd className="text-slate-600">Base64 encoded string of Shortcode + Passkey + Timestamp</dd>
              </div>
              <div>
                <dt className="font-medium">Timestamp</dt>
                <dd className="text-slate-600">Current time in YYYYMMDDHHMMSS format (e.g., "20250220183000")</dd>
              </div>
              <div>
                <dt className="font-medium">TransactionType</dt>
                <dd className="text-slate-600">"CustomerPayBillOnline" or "CustomerBuyGoodsOnline"</dd>
              </div>
              <div>
                <dt className="font-medium">Amount</dt>
                <dd className="text-slate-600">Amount to be charged (e.g., "100")</dd>
              </div>
              <div>
                <dt className="font-medium">PartyA</dt>
                <dd className="text-slate-600">Customer's phone number (e.g., "254712345678")</dd>
              </div>
              <div>
                <dt className="font-medium">PartyB</dt>
                <dd className="text-slate-600">Business shortcode (e.g., "174379")</dd>
              </div>
              <div>
                <dt className="font-medium">PhoneNumber</dt>
                <dd className="text-slate-600">Phone number prompted to enter PIN (e.g., "254712345678")</dd>
              </div>
              <div>
                <dt className="font-medium">CallBackURL</dt>
                <dd className="text-slate-600">URL to receive callback (e.g., "https://yourapp.com/callback")</dd>
              </div>
              <div>
                <dt className="font-medium">AccountReference</dt>
                <dd className="text-slate-600">Account identifier (e.g., "Order123")</dd>
              </div>
              <div>
                <dt className="font-medium">TransactionDesc</dt>
                <dd className="text-slate-600">Description of the transaction (e.g., "Order123 Payment")</dd>
              </div>
            </dl>
            <h4 className="text-base sm:text-lg font-medium mb-2 mt-4">Password Generation:</h4>
            <p className="text-slate-600 mb-2">
              Concatenate Shortcode, Passkey, and Timestamp, then Base64 encode. Example: <code>174379 + abc123 + 20250220183000</code> → <code>MTc0Mzc5YWJjMTIzMjAyNTAyMjAxODMwMDA=</code>
            </p>
            <h4 className="text-base sm:text-lg font-medium mb-2 mt-4">Example Request:</h4>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  {`{
  "BusinessShortCode": "174379",
  "Password": "MTc0Mzc5YWJjMTIzMjAyNTAyMjAxODMwMDA=",
  "Timestamp": "20250220183000",
  "TransactionType": "CustomerPayBillOnline",
  "Amount": "100",
  "PartyA": "254712345678",
  "PartyB": "174379",
  "PhoneNumber": "254712345678",
  "CallBackURL": "https://yourapp.com/callback",
  "AccountReference": "Order123",
  "TransactionDesc": "Order123 Payment"
}`}
                </code>
              </pre>
            </div>
            <h4 className="text-base sm:text-lg font-medium mb-2 mt-4">Example Response:</h4>
            <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  {`{
  "MerchantRequestID": "29115-34620561-1",
  "CheckoutRequestID": "ws_CO_20152023183000",
  "ResponseCode": "0",
  "ResponseDescription": "Success. Request accepted for processing",
  "CustomerMessage": "Success. Request accepted for processing"
}`}
                </code>
              </pre>
            </div>
          </div>

          {/* C2B */}
          <div className="mb-6">
            <h3 className="text-lg sm:text-xl font-medium mb-3">3. C2B (Customer to Business)</h3>
            <p className="text-slate-600 mb-2">Register URLs to receive payment notifications.</p>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  POST /mpesa/c2b/v1/registerurl
                </code>
              </pre>
            </div>
            <h4 className="text-base sm:text-lg font-medium mb-2">Parameters:</h4>
            <dl className="space-y-2">
              <div>
                <dt className="font-medium">ShortCode</dt>
                <dd className="text-slate-600">Your business shortcode (e.g., "600998")</dd>
              </div>
              <div>
                <dt className="font-medium">ResponseType</dt>
                <dd className="text-slate-600">"Completed" or "Cancelled"</dd>
              </div>
              <div>
                <dt className="font-medium">ConfirmationURL</dt>
                <dd className="text-slate-600">URL to receive confirmation (e.g., "https://yourapp.com/confirm")</dd>
              </div>
              <div>
                <dt className="font-medium">ValidationURL</dt>
                <dd className="text-slate-600">URL to validate transactions (e.g., "https://yourapp.com/validate")</dd>
              </div>
            </dl>
            <h4 className="text-base sm:text-lg font-medium mb-2 mt-4">Example Request:</h4>
            <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  {`{
  "ShortCode": "600998",
  "ResponseType": "Completed",
  "ConfirmationURL": "https://yourapp.com/confirm",
  "ValidationURL": "https://yourapp.com/validate"
}`}
                </code>
              </pre>
            </div>
          </div>

          {/* B2C */}
          <div className="mb-6">
            <h3 className="text-lg sm:text-xl font-medium mb-3">4. B2C (Business to Customer)</h3>
            <p className="text-slate-600 mb-2">Send money from your business to a customer's M-PESA account.</p>
            <div className="bg-slate-50 rounded-lg p-4 mb-2 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  POST /mpesa/b2c/v1/paymentrequest
                </code>
              </pre>
            </div>
            <h4 className="text-base sm:text-lg font-medium mb-2">Parameters:</h4>
            <dl className="space-y-2">
              <div>
                <dt className="font-medium">InitiatorName</dt>
                <dd className="text-slate-600">Initiator username (e.g., "testapi")</dd>
              </div>
              <div>
                <dt className="font-medium">SecurityCredential</dt>
                <dd className="text-slate-600">Encrypted password (see documentation)</dd>
              </div>
              <div>
                <dt className="font-medium">CommandID</dt>
                <dd className="text-slate-600">e.g., "BusinessPayment"</dd>
              </div>
              <div>
                <dt className="font-medium">Amount</dt>
                <dd className="text-slate-600">Amount to send (e.g., "100")</dd>
              </div>
              <div>
                <dt className="font-medium">PartyA</dt>
                <dd className="text-slate-600">Business shortcode (e.g., "600998")</dd>
              </div>
              <div>
                <dt className="font-medium">PartyB</dt>
                <dd className="text-slate-600">Customer's phone number (e.g., "254712345678")</dd>
              </div>
              <div>
                <dt className="font-medium">QueueTimeOutURL</dt>
                <dd className="text-slate-600">URL for timeout (e.g., "https://yourapp.com/timeout")</dd>
              </div>
              <div>
                <dt className="font-medium">ResultURL</dt>
                <dd className="text-slate-600">URL for results (e.g., "https://yourapp.com/result")</dd>
              </div>
              <div>
                <dt className="font-medium">Remarks</dt>
                <dd className="text-slate-600">Description (e.g., "Salary Payment")</dd>
              </div>
              <div>
                <dt className="font-medium">Occassion</dt>
                <dd className="text-slate-600">Optional occasion (e.g., "Monthly Salary")</dd>
              </div>
            </dl>
            <h4 className="text-base sm:text-lg font-medium mb-2 mt-4">Example Request:</h4>
            <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
              <pre className="text-sm">
                <code>
                  {`{
  "InitiatorName": "testapi",
  "SecurityCredential": "your_security_credential",
  "CommandID": "BusinessPayment",
  "Amount": "100",
  "PartyA": "600998",
  "PartyB": "254712345678",
  "QueueTimeOutURL": "https://yourapp.com/timeout",
  "ResultURL": "https://yourapp.com/result",
  "Remarks": "Salary Payment",
  "Occassion": "Monthly Salary"
}`}
                </code>
              </pre>
            </div>
          </div>
        </div>

        {/* Code Examples */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Code Examples</h2>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">Generate Access Token</h3>
              <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
                <pre className="text-sm">
                  <code>
                    {`// Generate Access Token
const getAccessToken = async () => {
  const auth = Buffer.from(\`\${consumerKey}:\${consumerSecret}\`).toString('base64');
  const response = await fetch(
    'https://sandbox.safaricom.co.ke/oauth/v1/generate?grant_type=client_credentials',
    {
      headers: { Authorization: \`Basic \${auth}\` }
    }
  );
  const data = await response.json();
  return data.access_token;
};`}
                  </code>
                </pre>
              </div>
            </div>
            <div>
              <h3 className="text-lg sm:text-xl font-medium mb-2">Initiate STK Push</h3>
              <div className="bg-slate-50 rounded-lg p-4 overflow-x-auto">
                <pre className="text-sm">
                  <code>
                    {`// Initiate STK Push
const initiateSTKPush = async (accessToken) => {
  const response = await fetch(
    'https://sandbox.safaricom.co.ke/mpesa/stkpush/v1/processrequest',
    {
      method: 'POST',
      headers: {
        Authorization: \`Bearer \${accessToken}\`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        BusinessShortCode: "174379",
        Password: "MTc0Mzc5YWJjMTIzMjAyNTAyMjAxODMwMDA=",
        Timestamp: "20250220183000",
        TransactionType: "CustomerPayBillOnline",
        Amount: "100",
        PartyA: "254712345678",
        PartyB: "174379",
        PhoneNumber: "254712345678",
        CallBackURL: "https://yourapp.com/callback",
        AccountReference: "Order123",
        TransactionDesc: "Order123 Payment"
      })
    }
  );
  const data = await response.json();
  return data;
};`}
                  </code>
                </pre>
              </div>
            </div>
          </div>
        </div>

        {/* Best Practices */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Best Practices</h2>
          <ul className="list-disc list-inside text-slate-600 space-y-2 ml-4">
            <li>Always store your credentials securely, preferably in environment variables.</li>
            <li>Implement proper error handling for network failures and API errors.</li>
            <li>Use the sandbox environment for testing before deploying to production.</li>
            <li>Cache your access token for up to 50 minutes to minimize authentication requests.</li>
            <li>Implement proper callback URL handling to process transaction results.</li>
            <li>Validate all incoming callbacks for authenticity using shared secrets.</li>
            <li>Use HTTPS for all API calls and callback URLs to ensure security.</li>
            <li>Implement rate limiting on your callback endpoints to prevent abuse.</li>
          </ul>
        </div>

        {/* Common Errors */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6 mb-10">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Common Errors</h2>
          <div className="overflow-x-auto">
            <table className="w-full text-left text-sm">
              <thead>
                <tr>
                  <th className="border-b p-2">ResultCode</th>
                  <th className="border-b p-2">Meaning</th>
                  <th className="border-b p-2">Solution</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b p-2">0</td>
                  <td className="border-b p-2">Success</td>
                  <td className="border-b p-2">None needed</td>
                </tr>
                <tr>
                  <td className="border-b p-2">1032</td>
                  <td className="border-b p-2">Customer cancelled</td>
                  <td className="border-b p-2">Ask user to retry if necessary</td>
                </tr>
                <tr>
                  <td className="border-b p-2">2001</td>
                  <td className="border-b p-2">Invalid access token</td>
                  <td className="border-b p-2">Generate a new access token</td>
                </tr>
                <tr>
                  <td className="border-b p-2">404</td>
                  <td className="border-b p-2">Invalid shortcode</td>
                  <td className="border-b p-2">Verify your shortcode in the portal</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Support */}
        <div className="bg-white rounded-xl shadow-sm border border-slate-200 p-6">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4">Support</h2>
          <ul className="list-disc list-inside text-slate-600 ml-4">
            <li>Website: <a href="https://developer.safaricom.co.ke" className="text-blue-600 hover:underline">developer.safaricom.co.ke</a></li>
            <li>Email: Check the portal for the latest support address.</li>
            <li>Phone: Dial 2222 (Safaricom line, if whitelisted).</li>
          </ul>
        </div>
      </div>
    </div>
  );
}