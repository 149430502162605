import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';

interface BreadcrumbProps {
  documentType: string;
  industry: string;
}

export default function Breadcrumb({ documentType, industry }: BreadcrumbProps) {
  const capitalizedDocType = documentType
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const capitalizedIndustry = industry
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return (
    <nav className="flex items-center space-x-2 text-sm text-slate-600 mb-8">
      <Link to="/" className="hover:text-slate-900">
        Home
      </Link>
      <ChevronRight className="w-4 h-4" />
      <Link to={`/${documentType}`} className="hover:text-slate-900">
        {capitalizedDocType}
      </Link>
      <ChevronRight className="w-4 h-4" />
      <span className="text-slate-900 font-medium">
        {capitalizedIndustry}
      </span>
    </nav>
  );
} 